import { Injectable } from '@angular/core';
import es from '@angular/common/locales/es';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ToastController, ModalController } from '@ionic/angular';
import * as constants from '../constants';
import { TYPE_SERVICES, TYPE_PILLOWS, TYPE_EXPERIENCES, TYPE_ALL } from '../constants';
import { Platform } from '@ionic/angular';
import { AlertController, LoadingController, IonSlides } from '@ionic/angular';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Storage } from '@ionic/storage';
import { IurbanService } from '../services/iurban.service';
import { ModalPage } from '../modal/modal.page';
import Parse from 'parse';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { timer, Observable, BehaviorSubject, of, from } from 'rxjs';
declare var Android: any;
import { setCORS } from "google-translate-api-browser";
import { UtilsService } from './utils.service';
import { NodeJSService } from './node-js.service';
import { DecimalPipe } from '@angular/common';
const translate = setCORS("http://cors-anywhere.herokuapp.com/");
import { PeerjsService } from './peerjs.service';
import { WebrtcService } from './webrtc.service';
import { JssipService } from './jssip.service';
import { JssipHttpsService } from './jssipHttps.service';
declare var annyang: any;
declare var google;

// import * as JsSIP from 'jssip';
// import { Web } from 'sip.js';
// import { SimpleUser, SimpleUserOptions } from 'sip.js/lib/platform/web';
declare var webphone_api: any;

interface Message {
  id: string;
  location: string;
  message: string;
  read: boolean
}

interface Order {
  id: number;
  id_menu_app: string;
  location: string;
  language: string;
  items: Item[];
  show_details: boolean;
  icon: string;
  created_date: string;
  cancelled: boolean;
  cancelled_date: string;
  preparing: boolean;
  preparing_date: string;
  ready_to_deliver: boolean;
  ready_to_deliver_date: string;
  ready_to_deliver_checked: boolean;
  scheduled: boolean;
  scheduled_date: string;
  observations: '';
}

interface Item {
  id: number;
  id_order: string;
  id_product: string;
  name_translations: [];
  modes: Mode[];
  dish: string;
  price: string;
  amount: string;
  notif_stock: boolean;
  stock: boolean;
  categories: {
    id_root_category: string;
    id_sub_category: string;
    id_category: string;
  };
}

interface Mode {
  name: string;
}

@Injectable({
  providedIn: 'root'
})

export class RestService {
  scriptGoogle;
  TAG = 'HOTEL_DIGITAL';
  url_base = 'https://api.iurban.es/';
  asklocation = false;
  base64Form;
  annyang = annyang;
  database: any;
  messageType: any;
  observationType: any;
  observationMsg: any;
  alert: any;
  modal: any;
  loading: any;
  firstItem: any;
  levels = new Array();
  level = 0;
  pos = 0;
  slidesPortrait: IonSlides;
  slidesLandscape: IonSlides;
  uiTypeIurban = false;
  license = '';
  nameDevice = '';
  newcms = false;
  mac = '';
  contentID: any;
  source: any;
  languages: any;
  imageLang = '../../assets/imgs/language.png';
  langIndexes: Map<number, number>;
  contentTab: any;
  showToolBar = true;
  showLocation = true;
  showingModal = false;
  modalHeight = '50%';
  modalHeightHeader = '60%';
  modalHeightContent = '60%';
  modalHeightFooter = '60%';
  modalMarginTop = '40%';
  modalAction = '';
  tempSelectedFilters = new Map();
  locations = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
  lastUpdateTime = 0;
  // showingStandBy = false;
  secondsLapsedLastUpdate = 0;
  textInput = '';

  inHome = false;

  // DQ AGRO FORM
  formName = '';
  formEnterprise = '';
  formComments = '';
  formDate = '';

  // Modal vars
  alertType: string;
  checksFilters: any[][] = [[{}], [{}]];
  checksModes: any[][] = [[{}], [{}]];
  selectedFilters = new Array();
  selectedFiltersString = '';
  selectedModes = new Array();
  parentsFilters: any = [];
  indexMode = 0;
  logoModal = '';
  titleModal = '';
  messageModal = '';

  btnModal1 = { show: true, text: 'CANCELAR' };
  btnModal2 = { show: true, text: 'SIGUIENTE' };
  btnModal3 = { show: true, text: 'SIGUIENTE' };
  priceSelected = -1;
  countProduct = 1;
  cartProducts = [];
  maxAddProducts = 50;
  productToDelete: any;
  myInput: any;
  showInfoScreen = false;
  showInfoMessage = false;
  order_scheduled = false;
  schedule_date: any;
  comarca;
  soeID;
  weatherData;
  show_observations = false;
  observationSelected = '0';
  weatherAvailable = false;

  // Configuration for each Slider
  slideOpts = {
    /*initialSlide: 0,
    slidesPerView: 1,
    speed: 1000,
    delay: 0,
    loop: true,
    pager: false,
    autoplayDisableOnInteraction: false*/
    autoplayDisableOnInteraction: false,
    loop: true
  };

  updateContentTime = 45; // in minutos

  consts: any;
  standbyTimer: any;
  //standbyTransitionTimer: any;
  // controlInterval = new BehaviorSubject(false);
  standByTime = 60 * 1000;
  standbyTransitionTime = 5 * 1000;
  standByImagesPortrait = [];
  standByImagesLandscape = [];
  standbyActiveIndex: any;
  locationSize = '1.8em';

  imageTest: any;
  response: any = new Object();
  currentItems: any;
  currentProduct: any;
  currentLink: any;
  lastUrl: any;
  urlTranslation: string;
  appStarted = false;
  alarm = '2020-01-01T00:00Z';
  alarmEnabled = true;

  // url params
  url = '';
  hotel = '001';
  bookingNumber = '002-00016610';
  room = '012';
  usedLang = 1;
  codeUsedLang = 'es';
  typeReference = TYPE_ALL;
  // typeReference = TYPE_SERVICES;
  // typeReference = TYPE_PILLOWS;
  // typeReference = TYPE_EXPERIENCES;
  // token: string;
  //token = '$2y$10$5SE/vFeBA4mae7djMT.MnOmmK1EuGbHhVunpYzAZv4WZKw67.YzbC';
  token = '';

  colorText1 = '#FFFFFF';
  colorText2 = '#660000';
  colorText3 = '#FFFFFF';
  colorbackgroundItems = '#CC0000';
  colorbackgroundButtons = '#ffcc66';
  colorbackgroundApp = '#000000';
  colorbackgroundToolBar = '#000000';
  colorbackgroundTitle = '#000000EB';

  stats = [];
  statsTouchJSONObject = [];
  statsCallWaiterJSONObject = [];
  statsBillCardJSONObject = [];
  statsBillCashJSONObject = [];
  statsAddProductJSONObject = [];
  statsOrderJSONObject = [];
  statsEnterAppJSONObject = [];
  storageVar: any;

  showItemList: boolean;
  showAppsList: any;
  idApps: any;

  iurban: IurbanService;

  translateService: TranslateService;
  mBtnUnlockPressed = 0;
  contTouch = 0;
  showingAdminPanel = false;

  // LICENSE VARS
  infoText = '';
  showProgressbar = true;
  showIntroduceLicense = false;
  licenseWelcome = 'Bienvenido al sistema iTable.';
  introduceLicense = 'Por favor introduzca su licencia';
  showRedoButton = false;
  idLocation = '';
  location = '';
  locationTemp = '';
  showClickableLayer = false;
  itemsByRow = [];
  backBtnDisabled = false;
  online = true;
  marginNotif = '0px';

  // BACK4APP
  client: Parse.LiveQueryClient;
  subscription: any;
  orderId: any;
  order: any;
  allow_cart = true;
  //screenPortrait = true;
  showSleepButton = false;
  toast: any;

  // PRODUCT CATEGORIES
  rootCategory = 'null';
  subCategory = 'null';
  category = 'null';
  date: string;
  time: string;
  attendeesAmount = 1;
  bookingName: string;
  comments = '';
  _file: any;
  fromKiosk = false;
  sleeping = false;
  gridType = 2; // default
  caching = true;
  preload: HTMLImageElement;
  firstTime = true;
  sendingOrder = false;

  minDate: any;
  maxDate: any;

  minSateSchedule: any;
  maxStateSchedule: any;
  fixed_logo: any;
  arrayPrices = [];
  call = false;
  phone;
  chat = false;
  qr_demo_mode = false;
  messagesUnread = [];
  defaultResponses = [
    'Problema con televisor',
    'Otro problema',
    'Escribir mensaje'
  ];
  canSendMessage = true;
  callMode = 'DIRECTORY';
  callDestiny = '';
  maxDigits = 12;
  apiObject;
  device;
  /* Añadidas las variables politicText, closeText y errorSurveyText para las traducciones a distintos idiomas 27/09/2022 */
  translations = {
    politicText: {
      es: "Acepto la política de privacidad",
      en: "I accept the data privacy policy",
      fr: "J'accepte la politique de confidentialité",
      de: "Ich akzeptiere die Datenschutzbestimmungen",
      pt: "Aceito a política de privacidade",
      ca: "Accepto la política de privadesa"
    },
    closeText: {
      es: "Cerrar",
      en: "Close",
      fr: "Fermer",
      de: "Schließen",
      pt: "Fechar",
      ca: "Tancar"
    },
    errorSurveyText: {
      es: "Por favor, rellene todos los campos.",
      en: "Please fill in all the fields.",
      fr: "Veuillez remplir tout les champs.",
      de: "Bitte füllen Sie alle Felder aus.",
      pt: "Por favor preencha todos os campos.",
      ca: "Si us plau, ompliu tots els camps."
    }
  };
  
  usersVoip = [
    {
      name: '4000',
      user: '4000',
      password: 'Iurban'
    },
    {
      name: '4001',
      user: '4001',
      password: 'Iurban'
    },
    {
      name: '2010',
      user: '2010',
      password: '2010'
    },
    {
      name: '2011',
      user: '2011',
      password: '2011'
    },
    {
      name: 'webphonetest2',
      user: 'webphonetest2',
      password: 'webphonetest2'
    },
    {
      name: '2010',
      user: '2010',
      password: '2010'
    },
    {
      name: '2011',
      user: '2011',
      password: '2011'
    },
    {
      name: 'webphonetest2',
      user: 'webphonetest2',
      password: 'webphonetest2'
    }
  ];

  observationOptions = [
    {
      id: 0,
      name_translations: {
        es: 'Televisión',
        en: 'Television',
        ca: 'Televisió',
        fr: 'Télévision'
      },
      icon: '../../assets/imgs/waiter.png',
      selected: false
    },
    {
      id: 1,
      name_translations: {
        es: 'Baño',
        en: 'Bathroom',
        ca: 'Bany',
        fr: 'Salle de bains'
      },
      icon: '../../assets/imgs/waiter.png',
      selected: false
    },
    {
      id: 2,
      name_translations: {
        es: 'Puerta',
        en: 'Door',
        ca: 'Porta',
        fr: 'Porte'
      },
      icon: '../../assets/imgs/waiter.png',
      selected: false
    },
    {
      id: 3,
      name_translations: {
        es: 'Caja fuerte',
        en: 'Strongbox',
        ca: 'Caixa forta',
        fr: 'coffre fort'
      },
      icon: '../../assets/imgs/waiter.png',
      selected: false
    },
    {
      id: 4,
      name_translations: {
        es: 'Luz',
        en: 'Light',
        ca: 'llum',
        fr: 'lumière'
      },
      icon: '../../assets/imgs/waiter.png',
      selected: false
    },
    {
      id: 5,
      name_translations: {
        es: 'Aire acondicionado / calefacción',
        en: 'Air conditioning / heating',
        ca: 'Aire condicionat / calefacció',
        fr: 'Climatisation / chauffage'
      },
      icon: '../../assets/imgs/waiter.png'
    },
    {
      id: 6,
      name_translations: {
        es: 'Otros',
        en: 'Others',
        ca: 'Altres',
        fr: 'Autres'
      },
      icon: '../../assets/imgs/waiter.png',
      selected: false
    }
  ];

  destinys = [
    {
      ext: 1,
      destiny: 3
    },
    {
      ext: 2,
      destiny: 4
    },
    {
      ext: 3,
      destiny: 1
    },
    {
      ext: 4,
      destiny: 2
    },
  ];

  messageTypes = [];
  readResponseCallWaiter: any;
  ipPrinter: string;
  callBtnPosition = 'up';
  privacyAccept = false;
  covidAccept = false;
  privacyText: string;
  inGoItem = false;
  suggested = false;
  native = false;

  constructor(private httpClient: HttpClient, private toastController: ToastController, public platform: Platform,
    private alertCtrl: AlertController, private router: Router, public loadingController: LoadingController,
    private storage: Storage, private modalController: ModalController, private utils: UtilsService, public nodeJS: NodeJSService,
    public decimalPipe: DecimalPipe, public peerjsService: PeerjsService,
    public webrtcService: WebrtcService, public jssip: JssipService, public jssiphttps: JssipHttpsService
  ) {

    this.storageVar = storage;
    this.consts = constants;

    // console.log('webphone before');
    // webphone_api.parameters['autostart'] = 0;


  }

  onInternetConnected() {
    // alert('on internet connected');
    this.online = true;
    console.log('on internet connected');
    this.presentToastType('Conectado a internet', 'success');
    return 'home: typescript: on internet connected';
  }

  onInternetDisconnected() {
    // alert('onInternetDisconnected');
    this.online = false;
    console.log('on internet disconnected');
    this.presentToastType('Se ha perdido la conexión', 'danger');
    return 'home: typescript: on internet disconnected';
  }

  /* setupScreenOrientation() {
 
 
     this.locationSize = this.screenPortrait ? '1.8em' : '1.2em';
 
     window.addEventListener('orientationchange', data => {
      
       this.doOnOrientationChange();
     });
       
     this.doOnOrientationChange();
 
   }*/

  doOnOrientationChange() {
    /* switch(window.orientation) {  
       case -90: case 90:      
         console.log('screeneee ' + this.screenPortrait) 
         this.screenPortrait = (false);
         break; 
       default:
         this.screenPortrait = (true);
         break; 
     }*/



    // alert('portrait ' + this.screenPortrait);

    //this.showStandby = false;
    /* this.screenPortrait = this.screenOrientation.type === 'portrait-primary'
     || this.screenOrientation.type === 'portrait-secondary';*/

    /*this.locationSize = this.screenPortrait ? '1.8em' : '1.2em';

    if (this.showingStandBy) {
        setTimeout( () => {
         // console.log('aqui after');
        if (this.screenPortrait) {
         // console.log('aqui after2' + this.slidesPortrait);
          //if (this.slidesPortrait !== undefined) {
           // console.log('aqui after3');
             // this.slidesPortrait.slideTo(this.standbyActiveIndex, 0).then(() => {
               // console.log('on orientation change ' + this.screenPortrait.value);

                 this.showStandby = true;
             // });
          //}
        } else {
         // console.log('aqui after2 ' + this.slidesLandscape);
          //if (this.slidesLandscape !== undefined) {
           // console.log('aqui after3');
              //this.slidesLandscape.slideTo(this.standbyActiveIndex, 0).then(() => {
                //console.log('on orientation change ' + this.screenPortrait.value);

                this.showStandby = true;
             //});
         // }
        }

        }, 1);
    }*/

  }

  getCallBtnClass() {
    /*if (this.peerjsService.callState == constants.STATE_CALLING) {
      // console.error('is calling');
      // return 'btn animate-color-on';
      return 'btn-call-calling';
    } else if (this.peerjsService.callState == constants.STATE_ONLINE) {
      // console.error('is online');
      return 'btn-call-calling';
    } else if (this.peerjsService.callState == constants.STATE_HUNG) {
      // console.error('is hangup');
      // console.log('call hung');
      return 'btn-call';
    }*/


    if (this.response.configuration.call_type == constants.CALL_IP_NODE_JS) {
      if (this.webrtcService.callState == constants.STATE_CALLING) {
        return 'btn-call-calling';
      } else if (this.webrtcService.callState == constants.STATE_ONLINE) {
        return 'btn-call-calling';
      } else if (this.webrtcService.callState == constants.STATE_HUNG) {
        return 'btn-call';
      }
    } else if (this.response.configuration.call_type == constants.CALL_IP_CENTRALLY) {
      if (this.jssip.callState == constants.STATE_CALLING) {
        return 'btn-call-calling';
      } else if (this.jssip.callState == constants.STATE_ONLINE) {
        return 'btn-call-calling';
      } else if (this.jssip.callState == constants.STATE_HUNG) {
        return 'btn-call';
      }
    }else if (this.response.configuration.call_type == constants.CALL_IP_CENTRALLY_HTTPS) {
      if (this.jssiphttps.callState == constants.STATE_CALLING) {
        return 'btn-call-calling';
      } else if (this.jssiphttps.callState == constants.STATE_ONLINE) {
        return 'btn-call-calling';
      } else if (this.jssiphttps.callState == constants.STATE_HUNG) {
        return 'btn-call';
      }
    }




  }

  setupStandBy(appStarted: boolean) {
    if (this.response != undefined && this.response.standby != undefined) {
      // this.standByTime = this.response.standby.start_time * 1000;
      this.standByTime = this.response.standby.start_time * 1000;
      this.standbyTransitionTime = this.response.standby.transition_time * 1000;
    } else {
      this.standByTime = 60 * 1000;
    }

    if (!appStarted) {
      this.startTimer();
    }

    this.standByImagesLandscape = [];
    this.standByImagesPortrait = [];

    this.firstTime = false;
    for (let standby of this.response.standby.standby_elements) {
      this.checkIfMustShow(standby);
    }

    if (this.apiObject != undefined) {

    }

    if (this.slidesPortrait !== undefined) {
      this.slidesPortrait.update();
      console.log('stanby updated');
    }


    if (this.slidesLandscape !== undefined) {
      this.slidesLandscape.update();
      console.log('stanby updated');
    }

    this.utils.showStandby = false;
    setTimeout(() => {
      this.utils.showStandby = true;
    }, 1);

  }

  checkIfMustShow(currentSB: any) {
    if (currentSB.active === 1) {
      if (!currentSB.programmed !== null && currentSB.programmed === 1) {
        if (currentSB.programmed_date === 1) {
          if (this.isOnDate(currentSB)) {
            //console.log('programmed week ' + currentSB.programmed_week + 'on day');

            if (currentSB.programmed_week === 1) {
              // console.log('programmed_time ' + currentSB.programmed_time + ' on day ' + this.isOnDay(currentSB.days_week));

              if (this.isOnDay(currentSB.days_week)) {
                if (currentSB.programmed_time === 1 && this.isTimeInRange(currentSB)) {
                  this.pushImage(currentSB);
                }
              }
            } else {
              if (currentSB.programmed_time === 0 ||
                currentSB.programmed_time === 1 && this.isTimeInRange(currentSB)) {
                this.pushImage(currentSB);
              }
            }
          }
        } else {
          console.log('WEEK ' + (currentSB.programmed_week === 1));
          if (currentSB.programmed_week === 1) {
            console.log('programmed_time ' + currentSB.programmed_time);

            if (this.isOnDay(currentSB.days_week)) {
              if (currentSB.programmed_time === 0
                || (currentSB.programmed_time === 1 && this.isTimeInRange(currentSB))) {
                this.pushImage(currentSB);
              }
            }
          } else {
            if (currentSB.programmed_time === 0 ||
              (currentSB.programmed_time === 1) && this.isTimeInRange(currentSB)) {
              this.pushImage(currentSB);
            }
          }
        }
      } else {
        this.pushImage(currentSB);
      }
    }
  }

  pushImage(currentSB: any) {
    if (currentSB.image_landscape == null) {
      this.standByImagesLandscape.push(currentSB.image);
    } else {
      this.standByImagesLandscape.push(currentSB.image_landscape);
    }


    this.standByImagesPortrait.push(currentSB.image);

  }

  isTimeInRange(currentSB) {
    const now = new Date();

    const dateStart = this.getDate(currentSB.date_start,
      this.getTime(currentSB.hour_start).hour, this.getTime(currentSB.hour_start).minute);

    const dateEnd = this.getDate(currentSB.date_end,
      this.getTime(currentSB.hour_end).hour, this.getTime(currentSB.hour_end).minute);

    return ((now >= dateStart && now <= dateEnd));
  }

  isOnDay(days_week: string) {
    const charArray = days_week.split('');

    // console.log('day ' + (new Date().getDay() + ' programmed ' + charArray[new Date().getDay()-1] +
    // ' week ' + days_week + (charArray[new Date().getDay()] === '1') ));

    return charArray[new Date().getDay() - 1] === '1';
  }

  isOnDate(currentSB) {
    const now = new Date();
    now.setHours(0);
    now.setMinutes(0);
    const dateStart = this.getDate(currentSB.date_start, 0, 0);
    const dateEnd = this.getDate(currentSB.date_end, 0, 0);

    /*console.log('is on date, date_start ' + dateStart + ' day now ' +
    now + ' equals? ' + (now >= dateStart) );
  
    console.log('is on date, date_start ' + dateStart.getDate() + ' date_end ' +
    dateEnd.getDate() + ' ' + (  (now >= dateStart && now <= dateEnd)  ));*/

    return ((now >= dateStart && now <= dateEnd));
  }

  getTime(time) {
    const hour = time.substr(0,
      time.indexOf(':'));

    const minute = time.substr(
      time.indexOf(':') + 1,
      time.length);

    return {
      hour: hour,
      minute: minute
    };
  }

  showToolbar() {
    return (this.response.configuration.show_toolbar == null || this.response.configuration.show_toolbar == 1) &&
      (this.contentID != 380 && this.contentID != 382);
  }

  getDate(dateString, hour, minute) {
    const day = dateString.substr(0, dateString.indexOf('/'));
    const month = dateString.substr(dateString.indexOf('/') + 1, dateString.lastIndexOf('/') - 3) - 1;
    const year = dateString.substr(dateString.lastIndexOf('/') + 1, dateString.length);
    return new Date(year, month, day, hour, minute, 0, 0);
  }

  restartAutoPlay(slides: IonSlides) {
    console.log('in restart autplay ' + this.inGoItem)

    console.log('on restart autoplay')
    clearTimeout(this.standbyTimer);
    //if (!this.inGoItem) {
    setTimeout(() => {
      if (this.response.configuration.auto_load_survey === 1 && this.response.items.length === 1) {
        this.storageVar.get('survey').then((val) => {
          if (val != null) {
            this.router.navigateByUrl('/survey');
          } else {
            this.level = 0;
            this.router.navigateByUrl('/home');
          }
        });
      } else {
        this.level = 0;
        this.router.navigateByUrl('/home');
      }
      this.startTimer();
    }, 300);
    /*} else {
      this.startTimer();
      this.inGoItem = false;
    }*/

  }

  getItems() {
    let items = this.response.items;
    for (let index = 0; index < this.level; index++) {
      items = items[this.pos].items;
    }
    //console.log('currentitem ' + JSON.stringify(items));
    return items;
  }

  saveLicense() {
    this.storage.set('license', this.license);
    this.storage.set('nameDevice', this.nameDevice);
    //this.storage.remove('mac');
    this.storage.set('mac', this.mac);
    // this.showInfoDialog('saving license... ' + this.license);
  }


  startTimer() {

    if (this.standbyTimer != undefined) {
      clearTimeout(this.standbyTimer);
    }

    /*if(this.standbyTransitionTimer != undefined){
      console.log('sending standbytimer transition')
      clearTimeout(this.standbyTransitionTimer);
      this.controlInterval.next(true);
    }*/

    this.standbyTimer = setTimeout(() => {
      console.log('entering on standby...');
      this.formName = '';
      this.formEnterprise = '';
      this.dismissAlert();

      if (this.router.url === '/link') {
        this.showClickableLayer = true;
        console.log('showing clickable layer');
        // if iframe page show clickable layer, then user has 2 seconds to touch screen to prevent standby shows
        this.standbyTimer = setTimeout(() => {
          this.showClickableLayer = false;
          if (this.alert !== undefined) {
            this.alert.dismiss();
          }

          this.closeModal();


          this.enterStandby();
        }, 5000);
      } else {
        if (this.alert !== undefined) {
          this.alert.dismiss();
        }
        // console.log('stand by time ' + this.modal);
        this.observationMsg = '';
        this.selectObservationType(undefined);
        this.closeModal();

        console.log('entering on standby... aqui2 ');


        this.enterStandby();
      }
    }, this.standByTime);
  }

  enterStandby() {
    console.log('entering on standby... aqui ');

    // if (this.online) {
    if (this.contentID == 57) {
      //back to spanish language...
      let language = this.getLangByCode('es');
      this.onChangeLanguage(language['id'], language['code'], -1);

      this.router.navigateByUrl('/interactive-standby');
    } else {
      this.router.navigateByUrl('/standby');
    }
    /*}else{
      
      //  alert('No tiene conexión a internet');
      
    }*/


  }

  restartStandByTime() {
    // this.modal.dismiss();
    if (this.consts.STAND_BY_ACTIVE && this.appStarted) {
      console.log('in restart standby');
      //clearTimeout(this.standbyTimer);

      this.startTimer();
    }
  }

  getHeightLogo() {
    return this.contentID == 57 ? '50px' : '100%';
  }

  stopStandBy() {
    clearTimeout(this.standbyTimer);
  }

  startStandBy() {
    this.startTimer();
  }

  getSelectedFilters() {
    this.selectedFiltersString = '';
    for (let i = 0; i < this.tempSelectedFilters.size; i++) {
      if (this.selectedFilters[i] != undefined && this.selectedFilters[i].name_translations[this.usedLang] != undefined) {
        this.selectedFiltersString += (i === 0 ? '' : ' | ') + this.selectedFilters[i].name_translations[this.usedLang];
      }
    }
    return this.selectedFiltersString;
  }

  showFilters() {
    this.titleModal = this.translateService.instant('home.filters');
    this.messageModal = '';
    this.btnModal1 = { show: true, text: this.translateService.instant('cancel') };
    this.btnModal2 = { show: false, text: '' };
    this.btnModal3 = { show: true, text: this.translateService.instant('accept') };
  }

  showLanguage() {
    console.log('in show language');
    this.titleModal = 'IDIOMAS';
    this.messageModal = '';
    this.btnModal1 = { show: false, text: '' };
    this.btnModal2 = { show: false, text: '' };
    this.btnModal3 = { show: true, text: this.translateService.instant('cancel') };
  }

  showBill() {
    this.alertType = 'bill';
    this.titleModal = this.translateService.instant('modal.chooose_pay_method');
    this.messageModal = '';
    this.btnModal1 = { show: false, text: '' };
    this.btnModal2 = { show: false, text: '' };
    this.btnModal3 = { show: true, text: this.translateService.instant('cancel') };
  }

  showInfo(params) {
    this.titleModal = params.title;
    this.messageModal = params.message;

    this.btnModal1 = { show: true, text: this.translateService.instant('accept') };
    this.btnModal2 = { show: false, text: '' };
    this.btnModal3 = { show: false, text: '' };
  }

  showMessageTypes() {
    this.titleModal = this.translateService.instant('modal.message_type');
    // this.messageModal = params.message;

    this.btnModal1 = { show: true, text: this.translateService.instant('cancel') };
    this.btnModal2 = { show: false, text: '' };
    this.btnModal3 = { show: false, text: this.translateService.instant('cancel') };
  }

  showObservations() {
    this.locationTemp = this.location;
    this.logoModal = '../../assets/icons/icono_grande.svg';
    this.titleModal = this.translateService.instant('modal.can_help?');
    // this.messageModal = params.message;

    this.btnModal1 = { show: true, text: this.translateService.instant('cancel') };
    this.btnModal2 = { show: false, text: '' };
    this.btnModal3 = { show: true, text: this.translateService.instant('send') };
  }

  showUsersVOIP() {
    this.titleModal = this.translateService.instant('modal.users_voip_title');
    // this.messageModal = params.message;

    this.btnModal1 = { show: true, text: this.translateService.instant('cancel') };
    this.btnModal2 = { show: false, text: '' };
    this.btnModal3 = { show: false, text: this.translateService.instant('cancel') };
  }

  showConfirm(params) {
    this.alertType = 'confirm';

    if (this.modalAction === 'waiter') {
      this.titleModal = this.translateService.instant('information');
      this.messageModal = this.translateService.instant('call_waiter');
    } else if (this.modalAction === 'deleteProduct') {
      this.productToDelete = params;
      this.titleModal = this.translateService.instant('information');
      this.messageModal = this.translateJSON('delete_product');
    } else if (this.modalAction === 'empty_cart') {
      this.titleModal = this.translateService.instant('information');
      this.messageModal = this.translateService.instant('modal.empty_cart');
    }

    this.btnModal1 = { show: true, text: this.translateService.instant('cancel') };
    this.btnModal2 = { show: false, text: '' };
    this.btnModal3 = { show: true, text: this.translateService.instant('accept') };
  }

  translateJSON(text: string) {
    return this.translateService.instant(text);
  }

  showInput() {
    this.alertType = 'input';
    // this.titleModal = 'PANEL DE ADMINISTRADOR';
    if (this.modalAction === 'password') {
      this.titleModal = 'ADMINISTRADOR';
      this.messageModal = undefined;

      this.btnModal1 = { show: true, text: this.translateService.instant('cancel') };
      this.btnModal3 = { show: true, text: this.translateService.instant('accept') };

    } else if (this.modalAction === 'location') {
      console.log('en location aquiii');
      this.messageModal = this.translateService.instant('modal.enter_location');

      this.btnModal1 = { show: true, text: this.translateService.instant('cancel') };
      this.btnModal3 = { show: true, text: this.translateService.instant('accept') };

    } else if (this.modalAction === 'location_to_msg_type') {
      console.log('en location aquiii');
      this.messageModal = this.translateService.instant('modal.enter_location');

      this.btnModal1 = { show: true, text: this.translateService.instant('cancel') };
      this.btnModal3 = { show: true, text: this.translateService.instant('accept') };
    }


  }

  showPrices() {
    this.alertType = 'prices';
    this.titleModal = this.translateService.instant('modal.dishes');
    this.btnModal1 = { show: true, text: this.translateService.instant('cancel') };
    this.btnModal2 = { show: false, text: '' };

    if (this.currentProduct.modes.length > 0) {
      // console.log('on next pressed');
      this.btnModal3 = { show: true, text: this.translateService.instant('accept') };

    } else {
      // console.log('final3');
      // this.prepareDataProduct();
      this.btnModal3 = { show: true, text: this.translateService.instant('accept') };
    }
  }

  showModes() {
    this.alertType = 'modes';
    this.btnModal1 = { show: true, text: this.translateService.instant('cancel') };
    this.btnModal2 = { show: false, text: this.translateService.instant('cancel') };
    this.btnModal3 = { show: true, text: this.translateService.instant('accept') };

    let name_mode = this.currentProduct.modes[this.indexMode].name_translations[this.usedLang];

    this.titleModal = this.translateService.instant('choose') + ' ' + (name_mode != undefined ? name_mode.toUpperCase() : '');
  }

  showBooking() {
    this.date = new Date().toString();
    this.time = new Date().toString();
    this.comments = '';

    this.alertType = 'booking';
    this.titleModal = this.translateJSON('modal.order_form');

    this.btnModal1 = { show: true, text: this.translateService.instant('cancel') };
    this.btnModal2 = { show: false, text: '' };
    this.btnModal3 = { show: true, text: this.translateService.instant('accept') };
  }

  showAddProduct() {
    this.alertType = 'addProduct';

    this.titleModal = this.translateJSON('add_product');

    this.btnModal1 = { show: true, text: this.translateService.instant('cancel') };
    this.btnModal2 = { show: false, text: '' };
    this.btnModal3 = { show: true, text: this.translateService.instant('accept') };
  }

  async setOrder() {
    let result = false;

    switch (constants.API as any) {
      case constants.IURBAN: {
        console.log('in iurban order')

        if (this.response.configuration.id_destiny_order == constants.DESTINY_PRINTER) {

        } else if (this.response.configuration.id_destiny_order == constants.DESTINY_ORDER_PANEL) {

          this.setIurbanOrder();

          this.onResultOrder();
        }
        break;
      }
      default: {
        break;
      }
    }
  }

  onDestinyChange(event: any) {
    console.log('event ' + event + ' leng ' + this.callDestiny.length)

    /* if (this.callDestiny.length > 6) {
       // invalid character, prevent input
       event.preventDefault();
     }*/
  }

  onNumberClick(digit) {
    console.log('call in progress ' + this.jssip.callState)
    if (this.callDestiny.length < this.maxDigits) {
      this.callDestiny += digit;
    }


    this.jssip.sendTone(digit);

  }

  numberOnlyValidation(event: any) {
    const pattern = /[0-9+*#.,]/;
    let inputChar = String.fromCharCode(event.charCode);

    console.log('inputChar.length ' + this.callDestiny.length)

    if (!pattern.test(inputChar) || this.callDestiny.length >= this.maxDigits) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  deleteLastDigit() {
    console.log('this.callDestiny before ' + this.callDestiny)
    if (this.callDestiny.length > 0) {
      this.callDestiny = this.callDestiny.slice(0, -1);
    } else {
      this.callDestiny = '';
    }
    console.log('this.callDestiny after ' + this.callDestiny)
  }

  mouseDown() {
    console.log('mouse down')
  }

  callPhone() {
    if (this.response.configuration.call_type == undefined || this.response.configuration.call_type == constants.CALL_PHONE) {
      window.open('tel:' + this.phone);
    } else if (this.response.configuration.call_type == constants.CALL_IP_NODE_JS) {
      if (this.webrtcService.callState == constants.STATE_HUNG) {
        const destiny = this.webrtcService.getDestiny();
        console.log('destiny soy ' + destiny);
        this.webrtcService.call(destiny);
      } else if (this.webrtcService.callState == constants.STATE_ONLINE) {
        this.webrtcService.hangup();
      } else if (this.webrtcService.callState == constants.STATE_CALLING) {
        this.webrtcService.hangup();
      }
    } else if (this.response.configuration.call_type == constants.CALL_IP_CENTRALLY) {
      console.error('on click call ' + this.webrtcService.callState);

      if (this.jssip.callState == constants.STATE_HUNG) {
        let title = 'Extensiones';
        let message = '';
        this.showModal('users-voip', 'modal-dialer', title, message, 'cancel', null);
      } else if (this.jssip.callState == constants.STATE_ONLINE) {
        this.jssip.hangup(true);
      } else if (this.jssip.callState == constants.STATE_CALLING) {
        this.jssip.hangup(true);
      }
    }else if (this.response.configuration.call_type == constants.CALL_IP_CENTRALLY_HTTPS) {
      console.error('on click call ' + this.webrtcService.callState);

      if (this.jssiphttps.callState == constants.STATE_HUNG) {
        let title = 'Extensiones';
        let message = '';
        this.showModal('users-voip', 'modal-dialer', title, message, 'cancel', null);
      } else if (this.jssiphttps.callState == constants.STATE_ONLINE) {
        this.jssiphttps.hangup(true);
      } else if (this.jssiphttps.callState == constants.STATE_CALLING) {
        this.jssiphttps.hangup(true);
      }
    }
  }

  callByType(extension) {
    console.log('on call ext ' + JSON.stringify(extension))
    if (this.response.configuration.call_type == constants.CALL_IP_NODE_JS) {
      this.webrtcService.call(extension);
    } else if (this.response.configuration.call_type == constants.CALL_IP_CENTRALLY) {
      this.jssip.call(extension);
    }else if (this.response.configuration.call_type == constants.CALL_IP_CENTRALLY_HTTPS) {
      this.jssiphttps.call(extension);
    }
    /*  if (this.modal != undefined) { // close old modal
        this.modal.dismiss();
      }*/
  }

  totalExtensions() {
    let total = 0;
    this.response.configuration.extensions.forEach(ext => {
      if (ext.extension != this.response.configuration.extension_id) {
        total++;
      }
    });
    return total;
  }

  getOtherExtension(): any {
    let destiny;
    this.response.configuration.extensions.forEach(ext => {
      console.error('soy ' + ext.extension + ' otra ' + this.response.configuration.extension_id);
      if (ext.extension != this.response.configuration.extension_id) {
        destiny = ext.extension;
        console.error('devuelvo ' + ext.extension);
        return ext.extension;
      }
    });
    return destiny;
  }

  websocketTest() {
    const websocket = new WebSocket('ws://127.0.0.1:8080');

    websocket.onopen = function (evt) {
      // onOpen(evt)
      alert('opened');
    };

    websocket.onclose = function (evt) {
      // onClose(evt)
      alert('on close');

    };

    websocket.onerror = function (evt) {
      // onError(evt)
      alert('on error ' + JSON.stringify(evt));

    };

  }

  async presentAlertSending() {
    this.alert = await this.alertCtrl.create({
      cssClass: 'alertCustomCss',
      // header: 'Confirm!',
      message: 'Enviando datos, espere por favor...',
      backdropDismiss: false
      /* buttons: [
         {
           text: 'Okay',
           handler: () => {
             console.log('Confirm Okay');
             // this.Accept();
           }
         }
       ]*/
    });
    await this.alert.present();
    /*setTimeout(() => {
      this.formName = '';
      this.formSurname = '';
      this.formComments = '';
      alert.dismiss();
      this.presentToastType('Enviado correctamente', 'success');
    }, 2000);*/
  }

  async presentAlert(msg) {
    const alert = await this.alertCtrl.create({
      cssClass: 'alertCustomCss',
      // header: 'Confirm!',
      message: msg,
      buttons: [
        {
          text: 'Okay',
          handler: () => {
            console.log('Confirm Okay');
            // this.Accept();
          }
        }
      ]
    });
    await alert.present();
  }

  async presentAlertIncoming(peerdisplayname) {
    console.log('targetUser ' + peerdisplayname);
    const alert = await this.alertCtrl.create({
      cssClass: 'alertCustomCss',
      // header: 'Confirm!',
      message: 'Llamada entrante de ' + peerdisplayname,
      buttons: [
        {
          text: 'Rechazar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
            //this.Reject();
          }
        }, {
          text: 'Okay',
          handler: () => {
            console.log('Confirm Okay');
            //this.Accept();
          }
        }
      ]
    });
    await alert.present();
  }

  changeVisualCallMode() {
    this.callMode = this.callMode == 'DIRECTORY' ? 'DIALER' : 'DIRECTORY';
    this.callDestiny = '';
  }

  callByIp(userTo) {
    console.log('userto ' + userTo);
    // this.WebSocketTest();
    // alert('in call by ip')

    if (this.webrtcService.callState == constants.STATE_HUNG) {
      //  alert('is hung, calling ' + userTo.name)

      this.webrtcService.call(userTo.name);
    } else if (this.webrtcService.callState == constants.STATE_ONLINE) {
      // alert('is online...')

      this.webrtcService.hangup();
    } else if (this.webrtcService.callState == constants.STATE_CALLING) {
      // alert('is calling...')
      this.webrtcService.hangup();
    }


    try {
      this.modalController.dismiss();
    } catch (error) {
      console.log('error closing modal ' + error);
    }

  }

  getToolbarIconColor() {
    //return this.response.configuration.color_icon_toolbar;
    /*if (this.contentID == '57' || this.contentID == '361') {
      return '#1E2138';
    } else {
      return 'white';
    }*/
    return this.response.configuration.color_icon_toolbar;
  }

  shadowColor() {
    return this.response.configuration.text_shadow == 1 ? '0px 0px 10px black' : '0px 0px 0px transparent';
  }

  getFontFamily() {
    if (this.contentID == '57') {
      return 'Karla';
    } else if (this.contentID == '361') {
      console.error('soy fuente garamond')
      return 'Garamond';
    }else if (this.contentID == '466') {
      console.error('soy fuente narrow')
      return 'Arial Narrow';
    }
    else if (this.contentID == '455') {
      console.error('soy fuente narrow')
      return 'Arial Narrow';
    }
    else if (this.contentID == '180') {
      console.error('soy fuente Avenir')
      return 'Avenir';
    }
    else if (this.contentID == '525') {
      console.error('soy fuente narrow')
      return 'Avenir';
    }
    // else if ((this.contentID == '340') || (this.contentID == '348') || (this.contentID == '368') || (this.contentID == '373') || (this.contentID == '374') || (this.contentID == '375') || (this.contentID == '376') || (this.contentID == '404') || (this.contentID == '405') || (this.contentID == '406') || (this.contentID == '407') || (this.contentID == '408') || (this.contentID == '409') || (this.contentID == '410') || (this.contentID == '554')) {
    //   console.error('soy fuente Gotham')
    //   return 'Gotham';
    // }
    return 'Arial';
  }

  getCallBadgeColor() {
    if (this.response.configuration.call_type == constants.CALL_IP_NODE_JS) {
      return this.webrtcService.status == 'Registered' ? '#6BFF33' : 'red';
    } else if (this.response.configuration.call_type == constants.CALL_IP_CENTRALLY) {
      return this.jssip.status == 'Registered' ? '#6BFF33' : 'red';
    } else if (this.response.configuration.call_type == constants.CALL_IP_CENTRALLY_HTTPS) {
      return this.jssiphttps.status == 'Registered' ? '#6BFF33' : 'red';
    } else {
      return 'transparent';
    }
  }

  calcDistance(p1, p2) {
    return (google.maps.geometry.spherical.computeDistanceBetween(p1, p2) / 1000).toFixed(2);
  }

  emptyCart() {
    this.cartProducts = [];
    this.sendingOrder = false;
  }

  getPrinter() {
    console.log(this.response.printers[0].id);

    const printer = this.response.printers.filter(x => x.id == this.response.configuration.id_printer);

    console.log('printer: ' + JSON.stringify(printer));
    return printer;
  }

  showModalFilters() {
    console.log('show modal filters');
  }

  async openModal(alertType: string, action: string, params: any) {
    if (this.modal != undefined) { // close old modal
      this.modal.dismiss();
    }

    this.modalAction = action;
    console.log(' SHOW FILTERS ' + this.modalAction);

    this.alertType = alertType;
    this.messageModal = '';

    this.showingModal = true;
    this.modalHeight = '50%';

    this.schedule_date = new Date().toISOString();

    this.initModesModel();

    let modalClass = 'cart-modal';

    if (alertType === 'message-type') {
      modalClass = 'modal-list';
      this.showMessageTypes();
    } else if (alertType === 'observations') {
      modalClass = 'modal-observations';
      this.showObservations();
    } else if (alertType === 'users-voip') {
      modalClass = 'modal-dialer';
      this.showUsersVOIP();
    } else if (alertType === 'addProduct') {
      modalClass = 'modal-list';
      this.initPricesModel();
      // this.modalHeight = '20%';
      this.showAddProduct();
    } else if (alertType === 'modes') {
      modalClass = 'modal-list';
      // this.modalHeight = '35%';
      this.showModes();
    } else if (alertType === 'filters') {
      modalClass = 'modal-list';
      // this.modalHeight = '30%';
      this.showFilters();
    } else if (alertType === 'prices') {
      modalClass = 'modal-list';
      console.log('ON INIT PRICES');
      this.initPricesModel();
      // this.modalHeight = '30%';
      this.showPrices();
    } else if (alertType === 'booking') {
      console.log('in show form')
      modalClass = 'modal-form';
      // this.modalHeight = '20%';
      this.showBooking();
    } else if (alertType === 'input') {
      modalClass = 'modal-input';
      // this.modalHeight = '35%';

      if (action == 'location_to_msg_type') {
        this.messageType = params;
      }

      this.showInput();
    } else if (alertType === 'bill') {
      modalClass = 'modal-bill';
      // this.modalHeight = '20%';
      this.showBill();
    } else if (alertType === 'confirm') {
      // this.modalHeight = '20%';
      modalClass = 'modal-confirm';
      this.showConfirm(params);
    } else if (alertType === 'info') {
      modalClass = 'modal-confirm';
      this.modalHeight = '20%';
      this.showInfo(params);
    }

    this.modal = await this.modalController.create({
      component: ModalPage,
      cssClass: modalClass,
      componentProps: {
        'alertType': alertType
      },
      showBackdrop: true,
      backdropDismiss: false,
    });

    // this.log(this.TAG, 'in show modal');

    return await this.modal.present();
  }

  async showModal(alertType, modalClass, title, msg, btn1, btn3) {



    this.showingModal = true;
    this.titleModal = title;
    this.messageModal = msg;

    this.btnModal1 = { show: btn1 != null, text: btn1 != null ? this.translateService.instant(btn1) : '' };
    //this.btnModal2 = { show: btn2 != null, text: btn2 != null ? this.translateService.instant(btn2) : '' };
    this.btnModal3 = { show: btn3 != null, text: btn3 != null ? this.translateService.instant(btn3) : '' };

    this.alertType = alertType;
    this.modal = await this.modalController.create({
      component: ModalPage,
      cssClass: modalClass,
      componentProps: {
        'alertType': this.alertType
      },
      showBackdrop: true,
      backdropDismiss: false,
    });



    return await this.modal.present();
  }

  getCurrency() {
    return this.response.configuration !== undefined && this.response.configuration.currency !== undefined ?
      this.response.configuration.currency : '€';
  }

  getModePrice(childIndex) {
    return this.currentProduct.modes[this.indexMode].submodes[childIndex].price != undefined &&
      this.currentProduct.modes[this.indexMode].submodes[childIndex].price != 0 ?
      ' | ' + this.decimalPipe.transform(this.currentProduct.modes[this.indexMode].submodes[childIndex].price, '1.2-2', 'es')
      + ' ' + this.getCurrency() : '';
  }

  preSelectMinModes() {
    if (this.currentProduct.modes[this.indexMode].min > 0) {
      for (let index = 0; index < this.currentProduct.modes[this.indexMode].min; index++) {
        this.checksModes[this.indexMode][index].isChecked = true;
        this.selectedModes.push(this.checksModes[this.indexMode][index]);
      }
    }
  }

  showMessageModes() {
    let message = '';

    if (this.currentProduct.modes[this.indexMode].min > 0) {
      message += this.currentProduct.modes[this.indexMode].min + ' ' + (this.currentProduct.modes[this.indexMode].min > 1 ?
        ' ' + this.translateJSON('modal.modes') : this.translateJSON('modal.mode')) + ' ' + this.translateJSON('modal.minimum') + '. ';
    }

    if (this.currentProduct.modes[this.indexMode].max > 0) {
      message += ' ' + this.currentProduct.modes[this.indexMode].max + ' ' + (this.currentProduct.modes[this.indexMode].max > 1 ?
        ' ' + this.translateJSON('modal.modes') : this.translateJSON('modal.mode')) + ' ' + this.translateJSON('modal.maximum') + '.';
    }

    return message;
  }

  async presentLoading(msg: any) {
    this.loading = await this.loadingController.create({
      message: msg,
      showBackdrop: true,
      backdropDismiss: true,
    });

    await this.loading.present();
  }

  deleteProduct(i) {
    console.log('delete product');

    this.cartProducts.splice(i, 1);
  }

  closeModal() {
    this.log(this.TAG, 'showing modal ' + this.showingModal);
    if (this.showingModal) {
      this.selectObservationType(undefined);
      this.logoModal = '';
      this.textInput = '';
      this.locationTemp = '';
      this.observationSelected = '0';
      this.observationMsg = '';
      this.selectedModes = new Array();
      this.initModesModel();

      try {
        console.log('closing modal...')
        this.modalController.dismiss();
      } catch (error) {
        console.log('error closing modal ' + error);
      }
    }
  }
  getFilterColor() {
    return this.selectedFilters.length > 0 ? 'rgb(110, 5, 5)' : this.colorbackgroundButtons;
  }

  getFilterBtnClass() {
    // const cartButton = document.getElementById('filter-button');
    return this.selectedFilters.length > 0 ? 'btn filter-active' : 'btn';
  }

  getCartBtnClass() {
    return this.cartProducts.length > 0 ? 'btn animate-color-on' : 'btn-no-animate';
  }

  getData() {
    return this.cartProducts.length;
  }

  setupInterface() {
    document.documentElement.style.setProperty('--color1', this.colorbackgroundItems + ' !important');
    document.documentElement.style.setProperty('--ion-background-color', 'transparent');
    document.documentElement.style.setProperty('--button-background-color', this.colorbackgroundButtons);
    document.documentElement.style.setProperty('--button-background-items-color', this.colorbackgroundItems);
    document.documentElement.style.setProperty('--font-family', this.getFontFamily());
    document.documentElement.style.setProperty('--zoom', '0.75');
  }

  async presentToast(message) {
    try {
      this.toast.dismiss();
    } catch (e) { }

    this.toast = await this.toastController.create({
      message: message,
      cssClass: 'toast-mess',
      duration: 2000,
      position: 'top'
    });
    this.toast.present();
  }

  async presentToastType(message, color) {
    try {
      this.toast.dismiss();
    } catch (e) { }

    this.toast = await this.toastController.create({
      message: message,
      color: color,
      duration: 5000,
      position: 'top'
    });
    this.toast.present();
  }

  async presentToastButton(message, color) {
    try {
      this.toast.dismiss();
    } catch (e) { }

    this.toast = await this.toastController.create({
      message: message,
      color: color,
      showCloseButton: true,
      closeButtonText: 'ok',
      position: 'top'
    });
    this.toast.present();
  }

  checkPassword(password: string) {
    this.log(this.TAG, 'on password ' + password);
    switch (password) {
      case 'Rl1c3': {
        this.log(this.TAG, 'removing license...');

        this.storage.remove('license');
        this.storage.remove('nameDevice');
        this.storage.remove('mac');
        this.storage.remove('appID');
        this.storage.remove('location');
        window.localStorage.removeItem('contentID');

        this.showProgressbar = false;
        this.showIntroduceLicense = true;
        this.infoText = this.introduceLicense;
        this.showRedoButton = false;
        this.showAppsList = false;
        this.appStarted = false;

        this.license = '';
        this.nameDevice = '';
        this.mac = '';

        this.log(this.TAG, 'before android license... ' + this.isAndroid());

        if (this.isAndroid()) {
          (<any>window).UtilsAndroid.getMacAddress(this.onMACsuccess.bind(this), this.onMACfailure.bind(this));
        } else {
          this.router.navigate(['/license-manager'], { queryParams: { checkLicense: true } });
        }
        clearTimeout(this.standbyTimer);
        this.closeModal();
        break;
      }
      case 'ch4pp': {
        // console.log('change app');

        this.storage.remove('appID');
        this.showItemList = false;
        this.showAppsList = true;

        clearTimeout(this.standbyTimer);
        this.closeModal();
        this.router.navigateByUrl('/license-manager');
        break;
      }
      case 'c4rd': {
        console.log('en card');
        this.storageVar.remove('idCardChosen');
        this.showItemList = true;

        clearTimeout(this.standbyTimer);
        this.closeModal();
        this.router.navigateByUrl('/home');
        break;
      }
      case 'cl0c': {
        console.log('en location');

        this.modalAction = 'location';
        this.showInput();
        break;
      }
      case 'w1f1': {
        console.log('change wifi');

        clearTimeout(this.standbyTimer);
        this.closeModal();
        this.router.navigateByUrl('/networks');
        break;
      }
      case '.3x1t.': {
        console.log('on exit ');
        this.log(this.TAG, 'on exit');
        this.closeModal();

        if (this.source !== 'dom' && this.platform.is('android')) {
          (<any>window).UtilsAndroid.chooseLauncher(null, null);
          // (<any>window).UtilsAndroid.finish(null, null);
          (<any>window).UtilsAndroid.enablePullNotificationTouch(null, null);
        }
        break;
      }
      case 'n0c4rt': {
        this.allow_cart = false;
        this.storageVar.set('allow_cart', false);
        this.closeModal();
        break;
      }
      case 'c4rt': {
        this.allow_cart = true;
        this.storageVar.set('allow_cart', true);
        this.closeModal();
        break;
      }
      case 'n0sl33p': {
        this.showSleepButton = false;
        this.storageVar.set('showSleepButton', false);
        this.closeModal();
        break;
      }
      case 'sl33p': {
        this.showSleepButton = true;
        this.storageVar.set('showSleepButton', true);
        this.closeModal();
        break;
      }
      case 'r3l04d': {
        this.closeModal();
        window.location.reload();
        break;
      }
      case 'n00rd3r': {
        this.closeModal();
        break;
      }
      default: {
        this.presentToast('La contraseña no existe');
        this.closeModal();
        break;
      }
    }
    this.showingAdminPanel = false;
    this.textInput = '';
  }

  lockScreen(lock: boolean) {
    try {
      Android.lockScreen(lock);
    } catch (error) {
      console.log('error ' + error);
    }
  }

  onUnlockButton() {
    const date = new Date();
    const seconds = date.getSeconds();

    const lapsed = (seconds - this.mBtnUnlockPressed);

    if (lapsed === 0) {
      if (this.contTouch >= 2) {
        let title = 'Administrador'
        let message = '';
        this.showModal('password', 'modal-input', title, message, null, 'accept');

        this.contTouch = 0;
      } else {
        this.contTouch++;
      }
    } else {
      this.contTouch = 0;
    }
    this.mBtnUnlockPressed = seconds;
  }

  printTicket(msg: string, _location: string) {
    if (this.isAndroid()) {
      // this.showInfoDialog('pressed print ticket dom? ' + (this.source === 'dom') + ' android ' + this.platform.is('android'));

      const _products = [{
        name: 'jamon',
        count: 2
      }, {
        name: 'aceitunas',
        count: 5
      }];

      const prStr = JSON.stringify(_products);

      // const args: any[] = [];
      // args.push({message: msg, products: prStr, ip: '192.168.0.101', location: _location, port: '6001'});
      // args.push({products: _products});
      // this.log(this.TAG, 'args length ' + args.length);
      const args = { message: msg, products: prStr, ip: '192.168.0.105', location: _location, port: '6001' };

      (<any>window).UtilsAndroid.printTicket(args, this.success.bind(this), this.failure.bind(this));

      Android.printTicket(msg, _products, this.getPrinter().ip, this.getPrinter().port, location);
    }
  }

  success(result) {
    // this.doSomething(result);
    this.log(this.TAG, result);
    this.messageModal = 'Pedido enviado correctamente';
    this.openModal('confirm', 'confirm', null);
  }

  failure(err) {
    // this.doSomething(err);
    this.messageModal = 'Problema enviando el pedido';
    this.log(this.TAG, err);
  }

  onUnlockLauncher() {
    console.log('on unlock');
    // if (!this.showingAdminPanel) {
    const date = new Date();
    const seconds = date.getSeconds();

    // console.log('millis ' + seconds);
    this.log(this.TAG, 'lapsed ' + (seconds - this.mBtnUnlockPressed));
    // console.log('lapsed ' + ( (seconds - this.mBtnUnlockPressed) ));
    const lapsed = (seconds - this.mBtnUnlockPressed);

    if (lapsed === 0) {
      // console.log('perfe');
      if (this.contTouch >= 2) {
        if (this.isAndroid()) {
          (<any>window).UtilsAndroid.enablePullNotificationTouch(null, null);
          (<any>window).UtilsAndroid.chooseLauncher(null, null);
        }

        this.contTouch = 0;
      } else {
        this.contTouch++;
      }
    } else {
      this.contTouch = 0;
    }
    this.mBtnUnlockPressed = seconds;
  }

  getColSize(cartButton: boolean) {
    let buttonsSelected = 0;
    let size = 0;
    let cartButtonSize = 12;

    if (this.response.configuration.call_waiter) {
      buttonsSelected++;
    }
    if (this.response.configuration.get_bill) {
      buttonsSelected++;
    }

    if (buttonsSelected === 0) {
      return cartButton ? cartButtonSize : size;
    } else if (buttonsSelected === 1) {
      size = 6;
      cartButtonSize = 6;
      return cartButton ? cartButtonSize : size;
    } else if (buttonsSelected === 2) {
      size = 4;
      cartButtonSize = 4;
    }
    return cartButton ? cartButtonSize : size;
  }

  showCallBtn() {
    return this.call;
    /*return (this.call && this.response.configuration.call_type == constants.CALL_PHONE) ||
      (this.call && this.response.configuration.call_type == constants.CALL_IP &&
        this.webPhoneService.appState == this.webPhoneService.APP_STATE_STARTED);*/
  }

  callBtnDisabled() {
    const disabled = (this.response.configuration.call_type == constants.CALL_IP_NODE_JS && this.webrtcService.status == 'Unregistered') ||
      ((this.response.configuration.call_type == constants.CALL_IP_CENTRALLY && this.jssip.status == 'Unregistered') || (this.response.configuration.call_type == constants.CALL_IP_CENTRALLY_HTTPS && this.jssiphttps.status == 'Unregistered') );
    // console.error('disabled ' + this.response.configuration.call_type + ' centraly ' + disabled);
    return disabled;
  }

  dismissAlert() {
    try {
      this.alert.dismiss();
    } catch (error) {

    }
  }

  dismissAlertLoading() {
    try {
      this.loading.dismiss();
    } catch (error) {

    }
  }

  async showPrivacyDialog(msg: any) {

    try {
      this.alert.dismiss();
    } catch (error) {

    }

    this.alert = await this.alertCtrl.create({
      header: 'Información',
      cssClass: 'alertCustomCss',
      message: msg,
      buttons: ['OK']
    });

    return await this.alert.present();
  }

  async showInfoDialog(msg: any) {

    try {
      this.alert.dismiss();
    } catch (error) {

    }

    this.alert = await this.alertCtrl.create({
      header: 'Información',
      cssClass: 'alertCustomCss',
      message: msg,
      buttons: ['OK']
    });

    return await this.alert.present();
  }

  changeLanguage(id: any, code: any, tabNumber: number) {
    console.log('id language ' + id);
    this.usedLang = id;
    this.codeUsedLang = code.toLowerCase();
    this.imageLang = this.getImageLang(this.response.languages[this.langIndexes.get(this.usedLang)]);
    console.error('pongo imagelang ' + this.imageLang)

    if (this.router.url === '/product-details-page') {
      switch (tabNumber) {
        case -1: {
          this.contentTab = this.currentProduct.description_translations[this.usedLang];
          console.log('change language ' + this.currentProduct.description_translations[this.usedLang]);
          break;
        }
        case 0: {
          this.contentTab = this.currentProduct.description_translations[this.usedLang];
          break;
        }
        case 1: {
          this.contentTab = this.currentProduct.terms_translations[this.usedLang];
          break;
        }
      }
    }

    this.translateService.setDefaultLang(this.codeUsedLang);
    this.translateService.use(this.codeUsedLang);

    this.restartStandByTime();
    this.closeModal();
  }

  getIdLangByCode(code) {
    for (let i = 0; i < this.response.languages.length; i++) {
      if (this.response.languages[i].code == code) {
        return this.response.languages[i].id;
      }
    }

  }

  autoDetectLanguage() {
    this.langIndexes = new Map;
    // this.codeUsedLang = navigator.language.substr(0,2) ;
    // this.codeUsedLang = 'en';
    this.translateService.setDefaultLang(this.codeUsedLang);
    this.translateService.use(this.codeUsedLang);

    console.log("The language is: " + this.codeUsedLang + ' langs ' + this.langIndexes.size);

    for (let i = 0; i < this.response.languages.length; i++) {
      this.langIndexes.set(this.response.languages[i].id, i);

      if (this.response.languages[i].code == this.codeUsedLang) {
        this.usedLang = this.response.languages[i].id;
        this.imageLang = this.response.languages[this.langIndexes.get(this.usedLang)].image;
      }
    }
  }

  setupLanguage() {
    // patch to show language selector as text
    if (this.response.configuration.id == 1 || this.response.configuration.id == 361) {
      this.response.configuration.lang_selector = 'text';
    } else {
      this.response.configuration.lang_selector = 'flag';
    }

    this.langIndexes = new Map;
    let defaultIDlanguage = this.response.configuration.id_language;

    for (let i = 0; i < this.response.languages.length; i++) {
      this.langIndexes.set(this.response.languages[i].id, i);

      if (this.response.languages[i].id == defaultIDlanguage) {
        this.usedLang = this.response.languages[i].id;
        this.imageLang = this.response.languages[this.langIndexes.get(this.usedLang)].image;
        this.codeUsedLang = this.response.languages[i].code.toLowerCase();
        this.translateService.setDefaultLang(this.codeUsedLang);
        this.translateService.use(this.codeUsedLang);
      }
    }
  }

  differentLang(lang) {
    console.log('this ' + lang.code + ' used ' + this.codeUsedLang);
    return lang.code != this.codeUsedLang;
  }

  getTextSize() {
    return this.gridType == 5 ? '1.7em' : '1.2em';
  }

  getDescriptionSize() {
    return this.gridType == 5 ? '1.8em' : '1.2em';
  }

  getImageLang(language: any) {
    // console.log('language base64 ' + (language.image_base64 != undefined ));
    if (language.image_base64 != undefined) {
      console.log('language base64');
      return language.image_base64;
    } else {
      return language.image;
    }
  }

  initFiltersModel() {
    if (this.response.filters_without !== undefined) {
      for (let i = 0; i < this.response.filters_without.length; i++) {
        this.parentsFilters[i] = { showParent: false };
        for (let e = 0; e < this.response.filters_without[i].filter_elements.length; e++) {
          this.checksFilters[i][e] = { showParent: true, isChecked: false };
        }
      }
    }
  }

  initModesModel() {
    this.checksModes = [[{}], [{}], [{}], [{}], [{}], [{}], [{}]];

    if (this.currentProduct !== undefined && this.currentProduct.modes !== undefined) {
      for (let i = 0; i < this.currentProduct.modes.length; i++) {
        for (let e = 0; e < this.currentProduct.modes[i].submodes.length; e++) {
          this.checksModes[i][e] = { isChecked: false };
        }
      }
    }
  }

  getPrices() {
    this.arrayPrices = [];
    let i = 0;
    Object.keys(this.currentProduct.price_translations.values).forEach(key => {
      console.log('getting prices ');
      if (this.currentProduct.price_translations.values[key] != '') {
        // countPrices ++;
        this.arrayPrices.push({
          i: this.currentProduct.price_translations.values[key]
        })
      }
    })

    console.log('prices ' + this.arrayPrices.length);

    return this.arrayPrices;
  }

  initPricesModel() {
    this.getPrices();
    if (this.currentProduct !== undefined) {
      if (this.currentProduct.price_translations.values != undefined && this.currentProduct.price_translations.values.length > 0) {
        this.priceSelected = 0;
      }
    }
  }

  onPrinTicketSuccess(result) {
    // this.doSomething(result);
  }

  onPrinTicketFailure(err) {
    // this.doSomething(err);
  }

  public setIurbanOrder() {

    console.log('create database order');
    this.iurban.createOrder();


  }

  public onResultOrder() {
    this.cartProducts = [];
    this.sendingOrder = false;
    this.order_scheduled = false;

    this.presentToastButton(this.translateJSON('order_placed'), 'success');
    this.level = 0;
    this.router.navigate(['/home', { 'level': this.level }]);

  }

  updateLocationAndSendMessageType(sendMessage: boolean) {
    let _location = this.location != '' ? this.location : this.textInput;
    console.log('location ' + _location);

    this.closeModal();

    if (sendMessage) {
      if (_location != '') {
        this.sendMessageType(this.messageType, _location);
        this.messageType = undefined;

        let params = new HttpParams();
        params = params.append('mac', this.mac);
        params = params.append('location', _location);

        /* try {
           Android.updateLocation(_location);
         } catch (error) {
           console.log('error ' + error);
         }*/

        console.log('send message type');

        return new Promise(resolve => {
          this.httpClient.post(constants.URL_UPDATE_LOCATION, params).pipe().subscribe(data => {
            if (data['code'] === '200') {

            } else {
              // this.presentToast(data['message']);
              console.log(JSON.stringify(data['message']));
            }
            resolve(data);
          }, err => {
            this.loading.dismiss();
            console.log(err);
            // this.presentToast('Hubo un problema al hacer el pedido');
          });
        });
      } else {
        this.presentToastType(this.translateJSON('modal.empty_location'), 'danger');
      }
    }
  }

  updateLocationAndSendObservation(sendMessage: boolean) {
    let _location = this.location != '' ? this.location : this.textInput;
    console.log('location ' + _location + ' message ' + this.observationMsg);

    this.closeModal();

    if (sendMessage) {
      if (_location != '') {
        this.sendObservation(this.observationType, this.observationMsg, _location);
        this.messageType = undefined;

        let params = new HttpParams();
        params = params.append('mac', this.mac);
        params = params.append('location', _location);

        console.log('send message type');

      } else {
        this.presentToastType(this.translateJSON('modal.empty_location'), 'danger');
      }
    }
  }

  getObservationClass(observation) {
    return observation.selected ? 'btn-observation-selected' : 'btn-observation';
  }

  selectObservationType(observation) {
    for (let index = 0; index < this.observationOptions.length; index++) {
      this.observationOptions[index].selected = false;
    }
    if (observation != undefined) {
      observation.selected = true;
    }
  }

  checkLocationAndSendMessageType(alertType: string, action: string, messageType) {
    console.log('messagetype ' + JSON.stringify(messageType));
    this.messageType = messageType;
    let _location = this.location != '' ? this.location : this.textInput;
    if (_location != '') {
      this.updateLocationAndSendMessageType(true);
    } else {
      this.openModal(alertType, action, messageType);
    }
  }

  storeItemsByColumns() {
    let lastIndex = 0;
    this.itemsByRow = [];
    for (let row = 0; row < this.levels[this.level].length; row++) {
      this.itemsByRow[row] = {
        column1: this.levels[this.level][lastIndex + row],
        column2: this.levels[this.level][lastIndex + row + 1]
      };
      lastIndex = row + 1;
    }
  }

  getItem(item: any) {
    if (item.type === constants.ITEM_TYPE_PRODUCT) {
      if (item.product != undefined) {
        return item.product;
      } else {
        return item;
      }
    } else if (item.type === constants.ITEM_TYPE_LINK) {
      return item.link;
    } else {
      return item;
    }
  }

  isProduct(item: any) {
    return item.type === constants.ITEM_TYPE_PRODUCT || item.product != undefined;
  }

  getImageByType(item: any) {
    const realItem = this.getItem(item);
    let image;
    let api = constants.API;

    image = (api === constants.LOCALHOST ? "data:image/jpeg;base64," +
      (realItem.image_1.replace(/['"]+/g, '')) : realItem.image_1);

    if (image === null) {
      image = '../../assets/imgs/no_photo.png';
    }

    // console.log('in image ' + this.image_he);
    return image;
  }


  addProduct() {
    if (this.countProduct < this.maxAddProducts) {
      this.countProduct++;
    }
  }

  subtractProduct() {
    if (this.countProduct > 1) {
      this.countProduct--;

      // this.cartProducts.splice(this.cartProducts.findIndex(x => x.product.id == this.currentProduct.id), 1);
    }
  }

  getToolbarClass() {
    let number_of_columns = 0;

    if (this.response.configuration.call_waiter === 1) {
      number_of_columns++;
    }
    if (this.response.configuration.get_bill === 1) {
      number_of_columns++;
    }
    if (this.response.configuration.skype === 1) {
      number_of_columns++;
    }
    if (this.response.configuration.wifi === 1) {
      number_of_columns++;
    }

    if (constants.SHOW_BUTTON_FOOD) {
      number_of_columns++;
    }


    if (number_of_columns === 1) {
      return 'grid-one-col';
    } else if (number_of_columns === 2) {
      return 'grid-two-col-auto';
    } else if (number_of_columns === 3) {
      return 'grid-three-col';
    }
  }

  goHome() {
    this.countProduct = 1;
    if (this.router.url === '/home') {
      if (this.level > 0) {
        this.level = 0;
      }
    } else {
      this.level = 0;
      this.router.navigateByUrl('/home');
    }

    this.restartStandByTime();
    this.storeItemsByColumns();
  }

  onBackPressed() {
    this.countProduct = 1;
    console.log('level ' + this.router.url);

    if (this.router.url.startsWith('/home')) {
      if (this.level > 0) {
        this.level--;
      }

      console.log('in home ' + this.level);


      if (this.level === 0) {
        this.rootCategory = 'null';
      } else if (this.level === 1) {
        this.subCategory = 'null';
      } else if (this.level === 2) {
        this.category = 'null';
      }
      this.router.navigate(['/home', { 'level': this.level }]);
    } else {
      window.history.back();
      // this.router.navigateByUrl('/home');
      // console.log('navigate to home');
    }

    this.restartStandByTime();
    this.countProduct = 1;

    this.storeItemsByColumns();
  }

  getBtnStatus() {
    return this.level > 0 ? '1' : '0.3';
  }

  setupUI() {
    if (this.response.configuration.text_1 != undefined) {
      this.colorText1 = this.response.configuration.text_1;
    }

    if (this.response.configuration.text_2 != undefined) {
      this.colorText2 = this.response.configuration.text_2;
    }

    if (this.response.configuration.text_3 != undefined) {
      this.colorText3 = this.response.configuration.text_3;
    }

    if (this.response.configuration.color_1 != undefined) {
      this.colorbackgroundItems = this.response.configuration.color_1;
    }

    if (this.response.configuration.color_2 != undefined) {
      this.colorbackgroundButtons = this.response.configuration.color_2;
    }

    if (this.response.configuration.color_3 != undefined) {
      this.colorbackgroundApp = this.response.configuration.color_3;
    }

    if (this.response.configuration.color_toolbar != undefined) {
      this.colorbackgroundToolBar = this.response.configuration.color_toolbar;
    }

    if (this.response.configuration.fondo_titulo != undefined) {
      this.colorbackgroundTitle = this.response.configuration.fondo_titulo + 'EB';
    }
  }



  log(tag, message) {
    console.log(message);
    if (this.isAndroid()) {
      (<any>window).UtilsAndroid.log({ TAG: tag, message: message }, null, null);
    }
  }

  logError(tag, message) {
    console.log(message);
    if (this.source !== 'dom' && this.platform.is('android')) {
      (<any>window).UtilsAndroid.logError({ TAG: tag, message: message }, null, null);
    }
  }

  verifyTableOrRoom() {
    console.log('on verify table')
    if (this.contentID != constants.CIVICITI && this.contentID != constants.CIVICITI_DEMOS) {


      if (this.location === '' || this.location == null || this.location == 'null') {
        this.openModal('input', 'location', null);
      }
    } else {
      this.openModal('language', '', null)
    }
  }

  showLangButton() {
    return this.response.languages.length > 1;
  }

  getImageLogo() {
    if (this.fixed_logo != undefined) {
      return this.fixed_logo;
    } else {
      if (this.response.configuration['logo_base64'] != undefined) {
        return this.response.configuration['logo_base64'];
      } else {
        return this.response.configuration['logo'];
      }
    }

  }

  removeAllEventListener(element, type) {
    element.myEvents.forEach(function () {
      element.myEvents[type].forEach(function (listener) {
        element.removeEventListener(type, listener);
      });
      element.myEvents[type] = [];
    });
  }



  hardwareButton() {

    document.removeEventListener("backbutton", this.onBackPressed);

    document.addEventListener("backbutton", this.onBackPressed);



    /*document.addEventListener("backbutton", e => {
    //alert('pulsaste back');
      e.preventDefault();
      this.onBackPressed();
    }, false);*/
  }

  isAndroid() {
    return this.source !== 'dom' && this.platform.is('android');
  }

  isBrowser() {
    return this.source === 'dom';
  }

  onMACsuccess(result) {
    // this.rest.mac = result;
    this.mac = result;

    this.log(this.TAG, 'in mac ' + this.mac);

    this.showIntroduceLicense = true;
    this.showProgressbar = false;

    // licenseWelcome = 'Bienvenido al sistema iTable.';
    // introduceLicense = 'Por favor introduzca su licencia';
    this.infoText = this.licenseWelcome + '\n' + this.introduceLicense;
    this.showIntroduceLicense = true;
    // this.checkLicenseIfConnect();

    this.showIntroduceLicense = true;
    this.log(this.TAG, 'in show introduce license ' + this.showIntroduceLicense);

    //this.router.navigate(['/license-manager'],{queryParams: {checkLicense:true}});
  }

  onMACfailure(err) {
    alert('error ' + err);
  }

  onImageError(event, errorImage) {
    console.log('on error image');
    event.target.src = errorImage;
  }

  initBack4App() {
    Parse.initialize('8PGy3bn21iRpZWTnhBJ1XeVJN9R34D2YWUifsItK', 'GDkzn0qBVNnQP8qWqSM9mvKDIdoupa5hiiyW6tPh');
    Parse.serverURL = 'https://testappsubdomain.back4app.io';

    const install = new Parse.Installation();
    install.set('deviceType', this.platform.platforms().toString());

    install.save(null, {
      success: (install) => {
        // Execute any logic that should take place after the object is saved.
        console.log('install id ' + install.id);

        this.client = new Parse.LiveQueryClient({
          applicationId: '8PGy3bn21iRpZWTnhBJ1XeVJN9R34D2YWUifsItK',
          serverURL: 'wss://' + 'testappsubdomain.back4app.io', // Example: 'wss://livequerytutorial.back4app.io'
          javascriptKey: 'GDkzn0qBVNnQP8qWqSM9mvKDIdoupa5hiiyW6tPh',
          masterKey: 'km8OU81AiZUK0gixWmIBT3UVquDHIDvM2PVY5vOx'
        });
        this.client.open();

      },
      error: (install, error) => {
        // Execute any logic that should take place if the save fails.
        // error is a Parse.Error with an error code and message.
        console.log('error ' + error.message);
      }
    });
  }

  createOrder(): boolean {
    if (!this.online) {
      // setTimeout( () => {
      this.presentToastType('Sin internet. Intentelo de nuevo en unos minutos', 'danger');
      // }, 2000);
    } else {
      this.sendingOrder = true;
      const nowDate = new Date();
      const date = nowDate.getDate() + '/' + (nowDate.getMonth() + 1) + '/' + nowDate.getFullYear();

      this.database.ref('orders').orderByChild('createdDate').equalTo(date).limitToLast(1).once('value').then(orders => {

        // console.log('aqui orders ' + JSON.stringify( orders ));


      });

    }

    return this.online;
  }

  async create(order, newId, nowDate: Date) {
    const date = nowDate.getDate() + '/' + (nowDate.getMonth() + 1) + '/' + nowDate.getFullYear();
    const time = nowDate.getHours() + ':' + nowDate.getMinutes();
    let post;

    console.log('order created ' + nowDate.toString());

    if (!this.order_scheduled) {
      this.schedule_date = '';
    }


    const result = await this.iurban.createOrder();
    console.log('result create ' + JSON.stringify(result));
    this.orderId = result['message'].id;


    this.cartProducts = [];
    this.sendingOrder = false;
    this.order_scheduled = false;

    this.presentToastButton(this.translateJSON('order_placed'), 'success');
  }

  async showOptionsMessage() {
    let alert = await this.alertCtrl.create({
      message: '¿En qué podemos ayudarle?',
      cssClass: 'chat-modal',
      inputs: [
        {
          type: 'radio',
          label: 'Problema con tv',
          value: 0
        },
        {
          type: 'radio',
          label: 'Llamar al servicio',
          value: 1
        },
        {
          type: 'radio',
          label: 'Otro',
          value: 2
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          }
        },
        {
          text: 'OK',
          handler: (data) => {
            console.log('OK clicked. Data -> ' + JSON.stringify(data));

            if (data == 0) {
              console.log('on PROBLEMA TV')
              this.sendMessage('Problema con el televisor');
            } else if (data == 1) {
              this.sendMessage('Llamar al servicio');
            } else if (data == 2) {
              this.showInputMessage();
            }

            alert.dismiss();
          }
        }
      ]
    });
    await alert.present();
  }

  async showInputMessage() {
    let alert = await this.alertCtrl.create({
      message: 'Write your message',
      cssClass: 'chat-modal',
      inputs: [
        {
          name: 'message',
          type: 'text',
          placeholder: 'Message'
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          }
        },
        {
          text: 'Send',
          handler: (data) => {


            if (data['message'].trim() != '') {
              this.sendMessage(data['message']);
              return true;
            } else {
              this.presentToast('El mensaje no puede estar vacío');
              return false;
            }



          }
        }
      ]
    });
    await alert.present();
  }

  sendMessage(_message) {
    console.log('location ' + JSON.stringify(_message));

    this.database.ref('messagesToService/' + this.location).push({
      location: this.location,
      message: _message,
      read: false
    });
    this.canSendMessage = false;
    this.presentToast('Mensaje enviado, le contestaremos lo antes posible');
  }

  async askMessage(): Promise<string> {
    return new Promise(async (resolve) => {
      let alert = await this.alertCtrl.create({
        message: 'Escriba un mensaje',
        inputs: [
          {
            name: 'message',
            placeholder: 'Mensaje',
            type: 'number'
          }
        ],
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            handler: data => {
              resolve(data);
            }
          },
          {
            text: 'Ok',
            handler: data => {
              if (data['message'].message != '') {
                this.sendMessage(data['message'].message);
              } else {
                this.presentToast('Le mensaje no puede estar vacío');
              }

              resolve(data);
            }
          }
        ]
      });
      await alert.present();
    });
  }

  readMessagesAdded() {
    //  console.log('reading messages from service ' + this.location)
    this.database.ref('messagesToClient/' + this.location).orderByChild('read').equalTo(false).on('child_added', async order => {
      const element: Message = JSON.parse(JSON.stringify(order)) as Message;
      element.id = order.key;

      let translate = await this.translate(element.message, this.codeUsedLang);

      console.log('new message to client ' + JSON.stringify(element.message))
      // this.messagesUnread.push(order);

      this.database.ref('messagesToClient/' + this.location + '/' + element.id).remove();
      this.canSendMessage = true;

      console.log('translate message ' + translate['text'])

      this.presentToastButton('Mensaje de recepción: ' + translate['text'], 'success');

    });
  }

  showExtension() {
    return this.response.configuration.extension_id != undefined && this.response.configuration.extension_id != ''
      && this.response.configuration.extension_id != 'null';
  }

  initStartVoiceRecognition() {
    this.webrtcService.initStartRecognition();
  }

  startVoiceRecognition() {
    this.webrtcService.startVoiceRecognition();
  }

  resumeVoiceRecognition() {
    annyang.resume();
  }

  stopVoiceRecognition() {
    // annyang.pause();
    this.webrtcService.stopVoiceRecognition();
  }

  disableOrdersRead() {
    this.database.ref('orders/' + this.orderId).orderByChild('preparing').equalTo(true).off();
    this.database.ref('orders/' + this.orderId).orderByChild('cancelled').equalTo(true).off();
    this.database.ref('orders/' + this.orderId).orderByChild('readyToDeliver').equalTo(true).off();
    this.database.ref('orders/' + this.orderId).child('items').orderByChild('stock').equalTo(false).off();
  }

  async dismissLoading(isConfirmed) {
    await this.loadingController.dismiss({ confirmed: isConfirmed });
  }

  createItems() {
    let modes = [];
    let items = [];

    console.log('price selected ' + this.priceSelected);
    let _id = 0;

    this.cartProducts.forEach(product => {
      console.log('product ' + JSON.stringify(product.product.name_exterior_translations[this.usedLang]) + ' dish ' +
        this.currentProduct.price_translations.keys[this.usedLang][this.priceSelected]);

      product.modes.forEach(mode => {
        // console.log('mode ' + JSON.stringify(mode.name_translations[this.usedLang]));
        modes.push(mode.name_translations[this.usedLang]);
      });

      items.push({
        itemId: _id,
        createdAt: '' + new Date(),
        productId: '' + product.product.id,
        nameTranslations: this.getNamesWithCodeLang(product),
        modes: '' + modes,
        dish: '' + this.currentProduct.price_translations.keys[this.usedLang][this.priceSelected],
        price: '' + product.price,
        amount: '' + product.amount,
        categories: {
          rootCategoryId: product.categories['rootCategory'],
          subCategoryId: product.categories['subCategory'],
          categoryId: product.categories['category']
        },
        stock: true,
        notifStock: false
      });
      _id++;

    });

    return items;
  }

  prepareDataProduct(fromModal, _date, _time) {
    // console.log('price selected ' + this.rest.currentProduct.price_translations);
    let price = this.priceSelected !== -1 && this.currentProduct.price_translations !== undefined
      && this.currentProduct.price_translations.values !== undefined
      && this.currentProduct.price_translations.values[this.priceSelected]
      !== undefined ? this.currentProduct.price_translations.values[this.priceSelected] : 0;

    console.log('ON PREPARE DATA PRODUCT ' + price);

    if (price == '')
      price = 0;

    const product = {
      product: this.currentProduct,
      modes: this.selectedModes,
      date: _date,
      hour: _time,
      price: price,
      amount: this.countProduct,
      categories: {
        rootCategory: this.rootCategory,
        subCategory: this.subCategory,
        category: this.category
      }
    };

    console.log('selected modes ' + JSON.stringify(this.selectedModes));

    this.cartProducts.push(product);

    // const cartButton = document.getElementById('cart-button');
    // cartButton.className = 'btn badge animate-color-on';

    // console.log('cart producrs ' + ( this.rest.cartProducts.length > 0 ));
    this.countProduct = 1;
    this.indexMode = 0;
    this.selectedModes = [];

    if (fromModal) {
      this.closeModal();
    }

    this.presentToast(this.translateJSON('product_added'));
  }

  stopRecognition() {
    this.webrtcService.stopVoiceRecognition();
  }

  isMobile() {
    /*return (
        (navigator.userAgent.match(/Android/i)) ||
        (navigator.userAgent.match(/webOS/i)) ||
        (navigator.userAgent.match(/iPhone/i)) ||
        (navigator.userAgent.match(/iPod/i)) ||
        (navigator.userAgent.match(/iPad/i)) ||
        (navigator.userAgent.match(/BlackBerry/i))
    );*/

    if (navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
      || navigator.userAgent.match(/Windows Phone/i)) {
      return true;
    } else {
      return false;
    }

  }

  addOrReplaceProduct(product) {
    let index = -1;
    index = this.containsProduct(product);
    console.log('tengo index ' + index);

    if (index == -1) {
      this.cartProducts.push(product);
    } else {
      console.log('tengo ' + this.cartProducts[index].amount + ' sumo ' + product.amount);
      this.cartProducts[index].amount += product.amount;
    }
  }

  containsProduct(product) {
    let index = -1;
    for (let index = 0; index < this.cartProducts.length; index++) {
      const element = this.cartProducts[index];
      console.log('compare id ' + JSON.stringify(element['product'].id) + ' with ' + (product.product.id));
      if (element['product'].id == product.product.id) {
        return index;
      }

    }
    return index;
  }

  addToCart(product) {
    for (let index = 0; index < this.cartProducts.length; index++) {
      const element = this.cartProducts[index];
      console.log('compare id ' + JSON.stringify(element) + ' with ' + (product));
    }
  }

  getModeNamesWithCodeLang(mode) {
    let obj = {};

    for (let index = 0; index < this.response.languages.length; index++) {
      const code = this.response.languages[index].code;
      console.log('lang code ' + code + ' name_trans ' + JSON.stringify(mode));

      if (mode.name_translations[this.response.languages[index].id] != undefined) {
        // console.log('añado en code ' + code + ': '  + product.product.name_exterior_translations[this.response.languages[index].id]);
        obj[code] = mode.name_translations[this.response.languages[index].id];
      }
    }
    return obj;
  }

  getNamesWithCodeLang(product) {
    // let langs = [];
    let obj = {};

    // console.log('translates ' + JSON.stringify(product.product));

    for (let index = 0; index < this.response.languages.length; index++) {
      const code = this.response.languages[index].code;
      console.log('lang code ' + code);

      if (product.product.name_exterior_translations[this.response.languages[index].id] != undefined) {
        console.log('añado en code ' + code + ': ' + product.product.name_exterior_translations[this.response.languages[index].id]);
        obj[code] = product.product.name_exterior_translations[this.response.languages[index].id];
      }
    }
    return obj;
  }

  onChangeLanguage(id: any, code: any, tabNumber?: number) {
    this.usedLang = id;
    this.codeUsedLang = code.toLowerCase();

    this.translateService.setDefaultLang(this.codeUsedLang);
    this.translateService.use(this.codeUsedLang);

    this.imageLang = this.getImageLang(this.response.languages[this.langIndexes.get(this.usedLang)]);

    console.log('image lang ' + this.imageLang);
    if (this.router.url === '/product-details-page') {
      switch (tabNumber) {
        case -1: {
          this.contentTab = this.currentProduct.description_translations[this.usedLang];
          console.log('change language ' + this.currentProduct.description_translations[this.usedLang]);
          break;
        }
        case 0: {
          this.contentTab = this.currentProduct.description_translations[this.usedLang];
          break;
        }
        case 1: {
          this.contentTab = this.currentProduct.terms_translations[this.usedLang];
          break;
        }
      }
    }

    try {
      this.loadScriptGoogle(this.codeUsedLang);
    } catch (error) {
      console.error('error ' + error);
    }
  }

  getLangByCode(codeLang) {
    let language;
    this.response.languages.forEach(lang => {
      if (codeLang == lang.code) {
        console.log('soy langcode ', lang.code);
        language = lang;
      }
    });
    return language;
  }

  onLinkProduct(item: any) {
    this.log(this.TAG, 'ON LINK PRODUCT ' + item.link.link_type + ' online ' + this.online);

    switch (item.link.link_type) {
      case constants.TYPE_LINK_URL_EXTERNAL: {
        /* this.currentLink = item.link.url_translations[this.usedLang];
   
         console.log(' IN EXTERNAL ');
         this.browser = this.iab.create(this.currentLink);
         this.browser.close();*/
        break;
      }
      case constants.TYPE_LINK_APP: {
        if (this.isAndroid()) {
          if (item.link.url_translations[this.usedLang] === 'ACTION_SET_ALARM') {
            (<any>window).UtilsAndroid.openAlarmManager(null, null);
          } else {
            const packageName = item.link.url_translations[this.usedLang];


          }
        }
        break;
      }
      case constants.TYPE_LINK_SURVEY: {
        if (this.online) {
          this.currentLink = item.link;
          console.log('survey ' + JSON.stringify(this.currentLink));

          if (this.currentLink != undefined) {
            this.storageVar.set('survey', this.currentLink);
            this.router.navigateByUrl('/survey');
          }

        } else {
          alert('No tiene conexión a internet');
        }
        break;
      }
      case constants.TYPE_LINK_URL_INTERNAL: {
        console.log(' IN INTERNAL ');

        this.currentLink = item.link.url_translations[this.usedLang];
        console.log('link is ' + this.currentLink);
        this.router.navigateByUrl('/link');
        break;
      }
      case constants.OPTION_CALL_WAITER: {
        console.log(' IN OPTION WAITER ');

        // this.rest.currentLink = item.link.url_translations[this.rest.usedLang];
        // console.log('link is ' + this.rest.currentLink);
        // this.router.navigateByUrl('/link');

        this.messageType =
        {
          es: 'Llamar al camarero',
          en: 'call waiter',
          fr: 'Appeler le serveur',
          de: 'Rufen Sie den Kellner'
        };

        this.checkLocationAndSendMessageType('input', 'location_to_msg_type', this.messageType);

        break;
      }
      case constants.OPTION_PAY: {
        console.log(' IN OPTION PAY');

        this.messageType =
        {
          es: 'Pago manual',
          en: 'manual payment',
          fr: 'paiement manuel',
          de: 'manuelle Zahlung'
        };

        this.checkLocationAndSendMessageType('input', 'location_to_msg_type', this.messageType);

        break;
      }
      default: {
        break;
      }
    }
  }


  getFile() {
    console.log('GET FILE');

    this.log(this.TAG, 'get file');

    const inputFile = document.getElementById("file-upload");
    // inputFile.value = '';
    inputFile.click();
  }

  changeListener($event): void {
    // this.rest.infoText += ' In changeFile';

    console.log('ON CHANGE FILE');
    this.log(this.TAG, 'ON CHANGE FILE');
    // use the 1st file from the list
    this._file = $event.target.files[0];

    const reader = new FileReader();

    reader.onload = () => {

      try {

        console.log('json ' + reader.result.toString());
        const json = JSON.parse(reader.result.toString());

      } catch (e) {
        // alert(e); // error in the above string (in this case, yes)!
      }

    };
    reader.onerror = () => {
      reader.abort();
    };
    reader.readAsText(this._file);
  }

  sleep(sleep: boolean) {  
    clearTimeout(this.standbyTimer);
    try {
      Android.setBrightness(sleep ? 0 : 1);
      // Android.lockScreen(sleep);
    } catch (error) {
      console.log('error ' + error);
    }
    if (sleep) {
      this.router.navigateByUrl('/sleep');
    } else {
      setTimeout(() => {
        this.sleeping = false;
      }, 1000);
    }
  }

  showCart() {
    this.router.navigateByUrl('/cart-page');
    this.restartStandByTime();
  }

  getLogoClass() {
    if (this.utils.isPortrait) {
      return 'grid-two-row';
    } else {
      return 'grid-two-col-auto';
    }
  }

  getHeightToolbar() {
    if (this.utils.isPortrait) {
      return '70px';
    } else {
      return '65px';
    }
  }

  getHeightOptions() {
    if (this.utils.isPortrait) {
      return '45px';
    } else {
      return '45px';
    }
  }

  translate(textFrom, langTo) {
    return new Promise(resolve => {
      translate(textFrom, { to: langTo })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          resolve(err);
          console.error(err);
        });
    });
  }

  sendObservation(observationType, observationMsg, location): Promise<any> {
    this.observationType = observationType;
    this.observationMsg = observationMsg;

    if (observationMsg == undefined)
      observationMsg = '';

    let params = new HttpParams();
    params = params.append('subject', 'Petición de soporte');
    params = params.append('id_menu_app', this.contentID);
    params = params.append('room', location);
    params = params.append('observation_type', JSON.stringify(observationType.name_translations['es']));
    params = params.append('message', observationMsg);

    return new Promise(resolve => {

      if (location != '') {
        this.httpClient.post(constants.URL_SEND_EMAIL, params).subscribe(data => {
          console.log('response type ' + data['code']);

          this.presentToastType(this.translateJSON('modal.message_type_response_ok'), 'success');

          console.log('aquiii response send message ' + JSON.stringify(data));

          resolve(data);
        }, err => {
          console.log('error ' + JSON.stringify(err));

          this.presentToastType(this.translateJSON('modal.message_type_response_error'), 'danger');

          resolve(err);
        });
      } else {
        resolve({ code: 500, message: 'Número de habitación obligatorio' });
      }
    });
  }

  sendMessageType(messageType, location) {
    this.messageType = messageType;

    // if (this.location != undefined) {
    let params = new HttpParams();
    params = params.append('id_menu_app', this.contentID);
    params = params.append('location', location);
    params = params.append('message_type', 'call_waiter');
    params = params.append('message', JSON.stringify(messageType));

    return new Promise(resolve => {
      this.httpClient.post(constants.SEND_MESSAGE, params).subscribe(data => {

        this.presentToastType(this.translateJSON('modal.message_type_response_ok'), 'success');
        try {
          this.modal.dismiss();
        } catch (error) {

        }
        console.log('aquiii response send message ' + data['message']);

        if (this.nodeJS.socketIO == undefined) {
          this.nodeJS.initServer();

          setTimeout(() => {
            if (!this.nodeJS.socketIO.connected) {
              this.presentToastType('El mensaje no se pudo enviar', 'danger');
            }
          }, 3000);

          this.nodeJS.socketIO.on('connect', () => {
            console.log('on node connected, location ' + location);
            this.nodeJS.socketIO.emit('save_socket_user', this.nodeJS.socketIO.id, this.contentID,
              location, constants.SENDER);

            this.readResponseMessageType();

            setTimeout(() => {
              console.log('sending message type... ' + JSON.stringify(data['message']));

              this.nodeJS.socketIO.emit('msg-type', this.contentID, data['message']);

              this.closeModal();
            }, 500);
          });

          this.nodeJS.socketIO.on('disconnect', () => {
            // this.nodeJS.socketIO = undefined;
            console.log('on node disconnected ');
          });
        } else {
          if (this.nodeJS.socketIO != undefined && this.nodeJS.socketIO.connected) {
            this.nodeJS.socketIO.emit('save_socket_user', this.nodeJS.socketIO.id, this.contentID,
              location, constants.SENDER);
            console.log('sending message type... ' + JSON.stringify(data['message']));
            this.readResponseMessageType();
            this.nodeJS.socketIO.emit('msg-type', this.contentID, data['message']);
            this.closeModal();
          } else {
            // this.presentToastType(this.translate('order_no_placed'), 'danger');
          }
        }
        resolve(data);
      }, err => {
        console.log('error ' + JSON.stringify(err));

        this.presentToastType(this.translateJSON('modal.message_type_response_error'), 'danger');

        resolve(err);
      });
    });
  }

  getLevelNumber(product) {
    if (product.level_number != undefined) {
      return product.level_number;
    } else {
      if (product.id_menu_level_1 != null) {
        return 1;
      } else if (product.id_menu_level_2 != null) {
        return 2;
      } else if (product.id_menu_level_3 != null) {
        return 3;
      } else {
        return null;
      }
    }
  }

  readResponseMessageType() {
    this.nodeJS.socketIO.off('msg-response');
    this.nodeJS.socketIO.on('msg-response', messageType => {
      console.log('on read message type ' + JSON.stringify(messageType));
      this.showInfoDialog(this.translateJSON('modal.message_type_auto_response'));
    });
  }

  readPing() {
    this.nodeJS.socketIO.off('ping');
    this.nodeJS.socketIO.on('ping', message => {
      console.log('on ping ');
      this.nodeJS.socketIO.emit('pong1');
    });
  }

  readNewMessages() {
    this.nodeJS.socketIO.off('new-message');
    this.nodeJS.socketIO.on('new-message', message => {
      console.log('on new message ' + JSON.stringify(message));
      this.presentToastType('', 'success');
    });
  }

  readUpdateStates() {
    this.nodeJS.socketIO.off('state-order');
    this.nodeJS.socketIO.on('state-order', data => {
      console.log('on update state order ' + JSON.stringify(data));

      let state = Object.keys(data.state)[0];
      console.log('state hereeee ' + state);

      if (state == 'preparing') {
        let message = {
          es: 'Su pedido está en preparación',
          en: 'Your order is in preparation',
          fr: 'Votre commande est en préparation',
          de: 'Ihre Bestellung ist in Vorbereitung',
          ca: 'La seva comanda està en preparació'
        };
        this.presentToastButton(message[this.codeUsedLang], 'success');
      } else if (state == 'cancelled') {
        let message = {
          es: 'Su pedido se ha cancelado',
          en: 'Your order has been canceled',
          fr: 'Votre commande a été annulée',
          de: 'Ihre Bestellung wurde storniert',
          ca: 'La seva comanda s\'ha cancel·lat'
        };
        this.presentToastButton(message[this.codeUsedLang], 'danger');
      }

    });
  }

  readUpdateStock() {
    this.nodeJS.socketIO.off('stock-order');
    this.nodeJS.socketIO.on('stock-order', data => {
      console.log('on update stock order ' + JSON.stringify(data));

      // let order: Order = data.order;
      let item: Item = data.item;

      if (item.notif_stock) {
        this.presentToastType('No queda ' + item.name_translations[this.codeUsedLang].replace(/(<p[^>]+?>|<p>|<\/p>)/img, ''), 'danger');
      }
    });
  }

  startReadResponseCallWaiter() {
    if (this.readResponseCallWaiter != undefined) {
      clearInterval(this.readResponseCallWaiter);
    }

    this.readResponseCallWaiter = setInterval(() => {
      console.log('Reading response callwaiter ...');

      this.readResponseMessageTypeRead();

    }, 1000);
  }

  readResponseMessageTypeRead() {
    let params = new HttpParams();
    params = params.append('location', this.location);

    console.log('reading messagetype... ');
    return new Promise(resolve => {
      this.httpClient.post(constants.READ_MESSAGE_BY_MENUAPP_CALL_WAITER + this.contentID, params).subscribe(data => {
        console.log('response message type ' + JSON.stringify(data['message']))

        console.log('code is ' + (data['code'] == 200));

        if (data['code'] == 200) {
          console.log('MESSAGE RECEIVED ')
          this.presentToastButton(this.translateJSON('modal.message_type_auto_response'), 'success');

          clearInterval(this.readResponseCallWaiter);

        } else {

        }
        // console.log('response message ' + JSON.stringify(data));

        // resolve(data);
      }, err => {
        console.log('error ' + JSON.stringify(err));
        // resolve(err);
      });
    });
  }

  iOS() {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
      || navigator.platform.includes("Mac")
      || (navigator.userAgent.includes("Mac") && "ontouchend" in document);
  }

  public async getAPIData() {
    //let params = new HttpParams();
    //params = params.append('soe', this.soeID);

    //return new Promise(resolve => {
     // this.httpClient.post('https://cms-iurban.com/api/farmacias/getAll', params).subscribe(data => {
        //console.log('apiobject ' + JSON.stringify(data));
     //   resolve(data);
     // }, err => {
        //this.dismissAlert();
      //  console.log(err);
        // this.presentToast('Hubo un problema al hacer el pedido');
     // });
   // });
  }

  zoomIn(map) {
    console.log('zet zoom ' + map.getZoom());
    map.setZoom(map.getZoom() + 1);
  }

  zoomOut(map) {
    map.setZoom(map.getZoom() - 1);
  }

  panBy(map, x, y) {
    map.panBy(x, y);
  }

  updateDatesWeatherData() {
    var today = new Date().getTime();

    var millisecondsOneDay = 24 * 60 * 60 * 1000;

    var plus_1 = new Date().setTime(today + millisecondsOneDay);

    var plus_2 = new Date().setTime(today + 2 * millisecondsOneDay);

    var plus_3 = new Date().setTime(today + 3 * millisecondsOneDay);

    var plus_4 = new Date().setTime(today + 4 * millisecondsOneDay);

    var plus_5 = new Date().setTime(today + 5 * millisecondsOneDay);

    var plus_6 = new Date().setTime(today + 6 * millisecondsOneDay);

    this.weatherData.today.date = new Date().setTime(today);

    this.weatherData.plus_1.date = new Date().setTime(plus_1);

    this.weatherData.plus_2.date = new Date().setTime(plus_2);

    this.weatherData.plus_3.date = new Date().setTime(plus_3);

    this.weatherData.plus_4.date = new Date().setTime(plus_4);

    this.weatherData.plus_5.date = new Date().setTime(plus_5);

    this.weatherData.plus_6.date = new Date().setTime(plus_6);

    return this.weatherData;
  }

  requestWeatherAPI(lat, lng) {
    //console.log('on lat ' + lat + ' lng ' + lng)
    this.weatherData = {};

    let params = new HttpParams();
    let dailyUrl = 'https://api.openweathermap.org/data/2.5/forecast/daily?lat=' + lat + '&lon=' + lng + '&cnt=7&mode=json&units=metric&APPID=7320eca88c0598a68dd33d761a826dd9';

    //let dailyUrl = 'https://api.openweathermap.org/data/2.5/forecast/daily';

    params = params.append('lat', lat);
    params = params.append('lon', lng);
    params = params.append('cnt', '7');
    params = params.append('mode', 'json');
    params = params.append('units', 'metric');
    params = params.append('APPID', '7320eca88c0598a68dd33d761a826dd9');

    this.httpClient.get(dailyUrl).subscribe(dailyData => {
      var weatherData = {
        'today': {},
        'plus_1': {},
        'plus_2': {},
        'plus_3': {},
        'plus_4': {},
        'plus_5': {},
        'plus_6': {}
      };

      var weather = dailyData['list'];
      var weatherToday = weather[0];
      var todayCondition = (constants.openWeatherToFontAwesomeWeatherIcons[weatherToday.weather[0].icon]) ?
        constants.openWeatherToFontAwesomeWeatherIcons[weatherToday.weather[0].icon] :
        'wi-day-sunny';
      weatherData.today = {
        'temp': {
          'max': Math.round(weatherToday.temp['max']),
          'min': Math.round(weatherToday.temp['min'])
        },
        'icon': weatherToday.weather[0].icon,
        'condition': todayCondition
      };

      for (var i = 1; i < weather.length; i++) {
        var weatherDay = weather[i];
        var dayCondition = (constants.openWeatherToFontAwesomeWeatherIcons[weatherDay.weather[0].icon]) ?
          constants.openWeatherToFontAwesomeWeatherIcons[weatherDay.weather[0].icon] :
          'wi-day-sunny';

        weatherData['plus_' + i] = {
          'temp': {
            'day': Math.round(weatherDay.temp['day']),
            'max': Math.round(weatherDay.temp['max']),
            'min': Math.round(weatherDay.temp['min'])
          },
          'icon': weatherDay.weather[0].icon,
          'condition': dayCondition
        };
      }

      weatherData = this.updateDatesWeatherData();
      this.weatherData.daily = weatherData;

      //  resolve( data );
    }, err => {
      console.log(err);
    });




    const hourlyUrl = 'https://api.openweathermap.org/data/2.5/forecast?lat=' + lat + '&lon=' + lng + '&mode=json&units=metric&APPID=7320eca88c0598a68dd33d761a826dd9';

    //const hourlyUrl = 'https://apis.openweathermap.org/data/2.5/forecast';

    this.httpClient.get(hourlyUrl).subscribe(hourlyData => {
      var weatherData = [];
      var weather = hourlyData['list'];

      for (var i = 0; i < 9; i++) {
        var weatherHour = weather[i];
        var dayCondition = (constants.openWeatherToFontAwesomeWeatherIcons[weatherHour.weather[0].icon]) ?
          constants.openWeatherToFontAwesomeWeatherIcons[weatherHour.weather[0].icon] :
          'wi-day-sunny';

        weatherData.push({
          'dt_txt': new Date(weatherHour.dt_txt),
          'temp': {
            'day': Math.round(weatherHour.main.temp),
            'max': Math.round(weatherHour.main['temp_max']),
            'min': Math.round(weatherHour.main['temp_min'])
          },
          'icon': weatherHour.weather[0].icon,
          'rain': (weatherHour.rain) ? weatherHour.rain['3h'] || 0 : 0,
          'condition': dayCondition
        });

      }

      this.weatherData.hourly = weatherData;

      this.weatherAvailable = true;
    }, err => {
      console.log(err);
    });

  }

  getMyDevice() {

    this.device = this.apiObject['device']['product'];

  }

  getDistances() {

    if (this.apiObject['items'] != undefined) {
      let currentPos = new google.maps.LatLng(this.device.latitude, this.device.longitude);

      for (let index = 0; index < this.apiObject['items'].length; index++) {
        const item = this.apiObject['items'][index];

        //if (item.active == 1) {
        let destiny = new google.maps.LatLng(item.product.latitude, item.product.longitude);
        // console.log('in get distance ' + item.product.latitude + item.product.longitude);
        let distance = this.calcDistance(currentPos, destiny);
        //console.log('distance ' + distance);
        this.apiObject['items'][index].product.distance = distance;
        //}
      }
      this.apiObject['items'] = this.sortJSON(this.apiObject['items']);
    }
  }

  loadScriptGoogle(lang?) {
    if (this.scriptGoogle == null) {
      this.scriptGoogle = document.createElement('script');
    } else {
      //console.error('deleting google script ' + this.scriptGoogle.id)
      this.scriptGoogle.remove();
      this.scriptGoogle = document.createElement('script');
    }

    this.scriptGoogle.id = "google-maps-script";
    this.scriptGoogle.type = 'text/javascript';
    this.scriptGoogle.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBNm8mQ0yYE692GyRk6aKrpEd7eZnbrjG4&sensor=false&libraries=geometry';

    if (lang) {
      this.scriptGoogle.src += '&language=' + lang;
    }

    document.getElementsByTagName("head")[0].appendChild(this.scriptGoogle)

  }

  sortJSON(arrayJSON) {
    let sorted = arrayJSON.sort((a, b) => {
      //console.log('compare ' + a.product.distance + ' con ' + b.product.distance);
      return a.product.distance - b.product.distance;
    });

    for (let index = 0; index < sorted.length; index++) {
      const item = sorted[index];
      //console.log('sorted ' + item.product.distance);
    }
    return sorted;
  }

  getHomeIcon() {
    return this.router.url.startsWith('/home') && this.level == 0 ? 'fa fa-home fa-3x' : 'fa fa-arrow-left fa-3x';
  }

  getTranslation(item) {
    if (item != undefined) {
      let title = '';
      let lang = item.code_lang != undefined && item.code_lang == true ? this.codeUsedLang : this.usedLang;

      if (item.name_exterior_translations != undefined) {
        title = item.name_exterior_translations[lang];
      } else {
        title = item.name_translations[lang];
      }
      return title != undefined ? title.replace(/(<p[^>]+?>|<p>|<\/p>)/img, '') : title;
    }
  }

  ifSafari() {
    var ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf('safari') != -1) {
      if (ua.indexOf('chrome') > -1) {
        return false;
      } else {
        return true;
      }
    }
  }

  capitalize(s) {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  goItem(item) {
    // setTimeout(() => {
    this.restartStandByTime()
    this.inGoItem = true;
    this.currentProduct = item.product;
    console.log('in go item ' + JSON.stringify(item.product));

    this.router.navigateByUrl('/product-details-page');
    // }, 2000);
  }

}
