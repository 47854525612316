import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController, NavController, NavParams } from '@ionic/angular';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { RestService } from '../../services/rest.service';
/* Añadido NavParams para poder pasar propiedades al modal.
  Añadido RestService para acceder a rest desde el .html 26/09/2022 */

@Component({
  selector: 'app-email-input',
  templateUrl: './email-input.page.html',
  styleUrls: ['./email-input.page.scss'],
})
export class EmailInputPage implements OnInit {

  public form: FormGroup;
  public mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;


  constructor(
    private formBuilder: FormBuilder,
    private modalCtrl: ModalController,
    private navCtrl: NavController,
    private params: NavParams,
    public rest: RestService,
    private alertCtrl: AlertController) { console.log('UserId', params.get('nombre')); }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required]],
    });
  }
  close(){
    this.modalCtrl.dismiss()
  }
  enviar(): void {
  //quitamos el class de error para comprobar de nuevo
    var get = document.getElementById('mail');
      get.classList.remove('error');
    var get = document.getElementById('name');
      get.classList.remove('error');
  //Comprobamos que el correo es valido y que el nombre no este vacio
    console.log(this.form.value)
    if(!this.form.value.email.match(this.mailformat)){
      var get = document.getElementById('mail');
      get.classList.add('error');
    }
    if(!this.form.value.name){
      var get = document.getElementById('name');
      get.classList.add('error');
    }
    if(this.form.value.name && this.form.value.email.match(this.mailformat)){
      console.log("false")
      this.modalCtrl.dismiss({
          'email': this.form.value
       });
    }
    
  }
  
}

